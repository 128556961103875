import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormUpdateProps } from '@boilerplate/types/entity';
import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import Notistack from '@/lib/notistack';
import LoggedHourEntity from '@/entities/loggedHour';
import { catchGraphQlError } from '@/lib/catchError';

  import CategoryEntity from '@/entities/category';
  import ProjectEntity from '@/entities/project';
  import UserEntity from '@/entities/user';
  import TenantEntity from '@/entities/tenant';

function LoggedHourBaseUpdateForm({ formOnly = false, onClose, Entity = LoggedHourEntity, id: propId, ...props }: BaseFormUpdateProps<typeof LoggedHourEntity.model>) {
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const id = propId || paramId;

  const Component = formOnly ? EntityForm : EntityFormPage;

  const { loading, data } = LoggedHourEntity.model.useGet({
    variables: {
      id,
            withCategory: true,
            withProject: true,
            withUser: true,
            withTenant: true,
    },
  });

  const [updateMutation, { loading: updateLoading }] = LoggedHourEntity.model.useUpdate();

  const relationsOptionsQuery = LoggedHourEntity.model.useRelationsOptions({
    variables: {
          withCategories: true,
          withProjects: true,
          withUsers: true,
          withTenants: true,
    }
  });

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(relationsOptionsQuery, [
              CategoryEntity,
              ProjectEntity,
              UserEntity,
              TenantEntity,
      ]),
    [relationsOptionsQuery]
  );

  const handleSubmit = (newData) => {
    newData.variables = getGraphqlData(Entity, newData.variables, 'update');

    updateMutation(newData)
      .then((result) => {
        Notistack.toast(t('crud:updatedItem', { item: t('entities:loggedHour') }), { variant: 'success' });

        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.updateLoggedHour);
      })
      .catch(catchGraphQlError);
  };

  if (loading || relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="update"
      Entity={Entity}
      loading={updateLoading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptions}
      data={data.loggedHour}
    />
  );
}

export default LoggedHourBaseUpdateForm;
