import { FilterProps } from '@boilerplate/components/entity/EntityTable/ColumnFilters/types';
import { Select, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TeamFilter({ fieldName, filterValue, setFilter, actions }: FilterProps) {
  const { useGetAllTenants } = actions;
  const { items: tenants = [] } = useGetAllTenants({
    fetchPolicy: 'cache-and-network',
  });

  const { t } = useTranslation();

  const updateFilter = (value) => {
    setFilter(value);
  };

  return (
    <Select
      labelId={`${fieldName}-label`}
      id={fieldName}
      size="small"
      sx={{ minWidth: '12ch' }}
      value={filterValue || ''}
      onChange={(e) => updateFilter(e.target.value)}
      placeholder="Select"
    >
      <MenuItem value="">
        <i>{t('crud:all')}</i>
      </MenuItem>
      {tenants.map((tenant) => (
        <MenuItem key={tenant.id} value={tenant.id}>
          {tenant.name}
        </MenuItem>
      ))}
    </Select>
  );
}
