import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        active: {
          type: Boolean,
          defaultValue: false,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        description: {
          type: String,
          optional: true,
          form: {
            component: 'LongText',
          },
          view: true,
        },
        branch: {
          type: String,
          optional: true,
          form: true,
          view: true,
        },
        subsidyPercentage: {
          type: Number,
          defaultValue: 100,
          form: true,
          view: true,
        },
        invoiceVatSubsidyEligible: {
          type: Boolean,
          defaultValue: false,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        reference: {
          type: String,
          optional: true,
          form: true,
          view: true,
        },
        status: {
          type: String,
          defaultValue: "concept",
          form: {
            component: 'Select',
          },
          view: true,
          allowedValues: [
            'concept',
            'submitted',
            'granted',
            'ongoing',
            'finalizationRequested',
            'finalized',
          ],
        },
        hourSubsidyType: {
          type: String,
          defaultValue: "fixedRate",
          form: {
            component: 'Select',
          },
          view: true,
          allowedValues: [
            'actualRate',
            'fixedSurcharge',
            'fixedRate',
          ],
        },
        maxHourlyRate: {
          type: Number,
          optional: true,
          form: true,
          view: true,
        },
        isSimple: {
          type: Boolean,
          defaultValue: false,
          form: false,
          view: true,
        },
        addendum: {
          type: Boolean,
          defaultValue: false,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        startDate: {
          type: Date,
          optional: true,
          form: {
            component: 'Date',
          },
          view: true,
        },
        endDate: {
          type: Date,
          optional: true,
          form: {
            component: 'Date',
          },
          view: true,
        },
        filingDate: {
          type: Date,
          optional: true,
          form: {
            component: 'Date',
          },
          view: true,
        },
        decisionDate: {
          type: Date,
          optional: true,
          form: {
            component: 'Date',
          },
          view: true,
        },
        customer: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'customer',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: false,
        },
        categories: {
          type: Array,
          relation: {
            name: 'HasMany',
            type: 'ManyToMany',
            model: 'category',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        'categories.$': {
          type: String,
          optional: true,
        },
        tenants: {
          type: Array,
          relation: {
            name: 'BelongsToMany',
            type: 'ManyToMany',
            model: 'tenant',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        'tenants.$': {
          type: String,
          optional: true,
        },
        loggedHours: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'loggedHour',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'loggedHours.$': {
          type: String,
          optional: true,
        },
        budgets: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'budget',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'budgets.$': {
          type: String,
          optional: true,
        },
        plannedHours: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'plannedHour',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'plannedHours.$': {
          type: String,
          optional: true,
        },
        hoursRequireds: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'hoursRequired',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'hoursRequireds.$': {
          type: String,
          optional: true,
        },
        invoices: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'invoice',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'invoices.$': {
          type: String,
          optional: true,
        },
        projectDates: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'projectDate',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'projectDates.$': {
          type: String,
          optional: true,
        },
        contracts: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'contract',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'contracts.$': {
          type: String,
          optional: true,
        },
        projectDocuments: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'projectDocument',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'projectDocuments.$': {
          type: String,
          optional: true,
        },
        userTenantRoles: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'userTenantRole',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'userTenantRoles.$': {
          type: String,
          optional: true,
        },
        regulation: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'regulation',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        responsibleUser: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          optional: true,
          form: true,
          view: true,
        },
        controlUser: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          optional: true,
          form: true,
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:project.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:project.name', 'entityFields:default.name'], '') || undefined,
        active: t(['entityFields:project.active', 'entityFields:default.active'], '') || undefined,
        description: t(['entityFields:project.description', 'entityFields:default.description'], '') || undefined,
        branch: t(['entityFields:project.branch', 'entityFields:default.branch'], '') || undefined,
        subsidyPercentage: t(['entityFields:project.subsidy_percentage', 'entityFields:default.subsidy_percentage'], '') || undefined,
        invoiceVatSubsidyEligible: t(['entityFields:project.invoice_vat_subsidy_eligible', 'entityFields:default.invoice_vat_subsidy_eligible'], '') || undefined,
        reference: t(['entityFields:project.reference', 'entityFields:default.reference'], '') || undefined,
        status: t(['entityFields:project.status', 'entityFields:default.status'], '') || undefined,
        hourSubsidyType: t(['entityFields:project.hour_subsidy_type', 'entityFields:default.hour_subsidy_type'], '') || undefined,
        maxHourlyRate: t(['entityFields:project.max_hourly_rate', 'entityFields:default.max_hourly_rate'], '') || undefined,
        isSimple: t(['entityFields:project.is_simple', 'entityFields:default.is_simple'], '') || undefined,
        addendum: t(['entityFields:project.addendum', 'entityFields:default.addendum'], '') || undefined,
        startDate: t(['entityFields:project.start_date', 'entityFields:default.start_date'], '') || undefined,
        endDate: t(['entityFields:project.end_date', 'entityFields:default.end_date'], '') || undefined,
        filingDate: t(['entityFields:project.filing_date', 'entityFields:default.filing_date'], '') || undefined,
        decisionDate: t(['entityFields:project.decision_date', 'entityFields:default.decision_date'], '') || undefined,
        customer: t(['entityFields:project.customer', 'entityFields:default.customer'], '') || undefined,
        categories: t(['entityFields:project.category', 'entityFields:default.category'], '') || undefined,
        tenants: t(['entityFields:project.tenant', 'entityFields:default.tenant'], '') || undefined,
        loggedHours: t(['entityFields:project.logged_hour', 'entityFields:default.logged_hour'], '') || undefined,
        budgets: t(['entityFields:project.budget', 'entityFields:default.budget'], '') || undefined,
        plannedHours: t(['entityFields:project.planned_hour', 'entityFields:default.planned_hour'], '') || undefined,
        hoursRequireds: t(['entityFields:project.hours_required', 'entityFields:default.hours_required'], '') || undefined,
        invoices: t(['entityFields:project.invoice', 'entityFields:default.invoice'], '') || undefined,
        projectDates: t(['entityFields:project.project_date', 'entityFields:default.project_date'], '') || undefined,
        contracts: t(['entityFields:project.contract', 'entityFields:default.contract'], '') || undefined,
        projectDocuments: t(['entityFields:project.project_document', 'entityFields:default.project_document'], '') || undefined,
        userTenantRoles: t(['entityFields:project.user_tenant_role', 'entityFields:default.user_tenant_role'], '') || undefined,
        regulation: t(['entityFields:project.regulation', 'entityFields:default.regulation'], '') || undefined,
        responsibleUser: t(['entityFields:project.responsible_user', 'entityFields:default.responsible_user'], '') || undefined,
        controlUser: t(['entityFields:project.control_user', 'entityFields:default.control_user'], '') || undefined,
        createdAt: t(['entityFields:project.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:project.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:project.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;