import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';
import axios from 'axios';

import ContractBaseOrderFormSchema from '@/_base/contract/ContractBaseOrderFormSchema';
import ContractBaseModel from '@/_base/contract/ContractBaseModel';
import ContractBaseSimpleSchema from '@/_base/contract/ContractBaseSimpleSchema';
import ContractBaseTableSchema from '@/_base/contract/ContractBaseTableSchema';
import ContractBaseInsertForm from '@/_base/contract/components/ContractBaseInsertForm';
import ContractBaseUpdateForm from '@/_base/contract/components/ContractBaseUpdateForm';
import ContractBaseTable from '@/_base/contract/components/ContractBaseTable';
import ContractBaseView from '@/_base/contract/components/ContractBaseView';

export type SalaryUser = { id: string; costPerHour: number; hourlyRateInputs: Record<string, number>; salarySlip?: string };

const ContractEntity: Entity<typeof ContractBaseModel> = {
  name: 'contract',

  simpleSchema: ContractBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(ContractBaseSimpleSchema),
    orderSchema: [...ContractBaseOrderFormSchema],
    component: ContractBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(ContractBaseSimpleSchema),
    orderSchema: [...ContractBaseOrderFormSchema],
    component: ContractBaseUpdateForm,
  },

  table: {
    schema: ContractBaseTableSchema,
    component: ContractBaseTable,
  },

  view: {
    component: ContractBaseView,
  },

  model: {
    ...ContractBaseModel,
    saveSalary: (projectId: string, tenantId: string, users: SalaryUser[]) => {
      return axios.post('/contract/salary', { projectId, tenantId, users }).then((response) => response.data);
    },
  },

  routes: {},

  settings: {
    displayField: 'id',
  },
};

export default ContractEntity;
