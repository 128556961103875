import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const ContractBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:contract.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'hoursPerWeek',
        label: t(['entityFields:contract.hours_per_week', 'entityFields:default.hours_per_week'], 'hoursPerWeek') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
                    sortable: true,
          numeric: true,
        optional: true,
      },
      {
          field: 'costPerHour',
        label: t(['entityFields:contract.cost_per_hour', 'entityFields:default.cost_per_hour'], 'costPerHour') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
                    sortable: true,
          numeric: true,
      },
      {
          field: 'startDate',
        label: t(['entityFields:contract.start_date', 'entityFields:default.start_date'], 'startDate') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
      },
      {
          field: 'endDate',
        label: t(['entityFields:contract.end_date', 'entityFields:default.end_date'], 'endDate') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'customCostPerHour',
        label: t(['entityFields:contract.custom_cost_per_hour', 'entityFields:default.custom_cost_per_hour'], 'customCostPerHour') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
                    sortable: true,
          numeric: true,
        optional: true,
      },
      {
          field: 'salarySlip',
        label: t(['entityFields:contract.salary_slip', 'entityFields:default.salary_slip'], 'salarySlip') || undefined,
          cell: CellRenderers['UploadRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['UploadFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'user',
        label: t(['entityFields:contract.user', 'entityFields:default.user'], 'user') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'tenant',
        label: t(['entityFields:contract.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'project',
        label: t(['entityFields:contract.project', 'entityFields:default.project'], 'project') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:contract.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:contract.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const ContractBaseTableSchema: TableSchema[] = [
  ...ContractBaseTableSchemaWithoutActions,
  actions,
];

export { actions, ContractBaseTableSchemaWithoutActions };
export default ContractBaseTableSchema;
