import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import PlannedHourBaseOrderFormSchema from '@/_base/plannedHour/PlannedHourBaseOrderFormSchema';
import PlannedHourBaseModel from '@/_base/plannedHour/PlannedHourBaseModel';
import PlannedHourBaseSimpleSchema from '@/_base/plannedHour/PlannedHourBaseSimpleSchema';
import PlannedHourBaseTableSchema from '@/_base/plannedHour/PlannedHourBaseTableSchema';
import PlannedHourBaseInsertForm from '@/_base/plannedHour/components/PlannedHourBaseInsertForm';
import PlannedHourBaseUpdateForm from '@/_base/plannedHour/components/PlannedHourBaseUpdateForm';
import PlannedHourBaseTable from '@/_base/plannedHour/components/PlannedHourBaseTable';
import PlannedHourBaseView from '@/_base/plannedHour/components/PlannedHourBaseView';

const PlannedHourEntity: Entity<typeof PlannedHourBaseModel> = {
  name: 'planned_hour',

  simpleSchema: PlannedHourBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(PlannedHourBaseSimpleSchema),
    orderSchema: [...PlannedHourBaseOrderFormSchema],
    component: PlannedHourBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(PlannedHourBaseSimpleSchema),
    orderSchema: [...PlannedHourBaseOrderFormSchema],
    component: PlannedHourBaseUpdateForm,
  },

  table: {
    schema: PlannedHourBaseTableSchema,
    component: PlannedHourBaseTable,
  },

  view: {
    component: PlannedHourBaseView,
  },

  model: PlannedHourBaseModel,

  routes: {},

  settings: {
    displayField: 'id',
  },
};

export default PlannedHourEntity;
