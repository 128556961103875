import { Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Content } from '@/stores/ContentStore';

const textColor = '#969696';

const useStyles = makeStyles()({
  benefitsText: {
    '& ul': {
      listStyle: 'none',
      padding: 0,

      '& li': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        paddingLeft: '35px',
        minHeight: '32px',

        '&::before': {
          content: '"✓"',
          position: 'absolute',
          left: '0',
          fontSize: '24px',
        },
      },
    },
  },
});

function AuthLayoutRight() {
  const { classes } = useStyles();

  return (
    <>
      <Content component={Typography} variant="h2" fontSize="18px" fontWeight={600} color={textColor}>
        auth.benefitsTitle
      </Content>

      <Content component={Typography} variant="body1" fontSize="16px" color={textColor} className={classes.benefitsText}>
        auth.benefitsText
      </Content>
    </>
  );
}

export default AuthLayoutRight;
