import React, { useMemo, useCallback } from 'react';
import ProjectEntity from '@/entities/project';
import { merge } from 'lodash';

import { ProjectSortFields, SortDirection, GetProjectsQueryVariables } from '@/graphql';
import TenantEntity from '@/entities/tenant';
import { BaseTableProps } from '@boilerplate/types/entity';
import EntityTable, {
  defaultPage,
  defaultPageSize,
  defaultSortOrder,
  getDefaultSortField,
} from '@boilerplate/components/entity/EntityTable/EntityTable';

export const useProjectsTable = ({ queryOptions }: { queryOptions?: GetProjectsQueryVariables } = {}) => {
  const defaultVariableOptions = useMemo<GetProjectsQueryVariables>(
    () =>
      merge(
        {
          sorting: {
            direction: defaultSortOrder === 'desc' ? SortDirection.Desc : SortDirection.Asc,
            field: getDefaultSortField(ProjectEntity.table.schema) as ProjectSortFields,
          },
          paging: {
            page: defaultPage,
            pageSize: defaultPageSize,
          },
          withTenants: true,
          withProjectDates: true,
          withProjectDocuments: true,
          withRegulation: true,
          withResponsibleUser: true,
          withControlUser: true,
        },
        queryOptions
      ),
    [queryOptions]
  );

  const {
    data: projectData,
    items = [],
    loading,
    refetch: originalRefetch,
    variables,
  } = ProjectEntity.model.useGetAll({
    fetchPolicy: 'cache-and-network',
    variables: defaultVariableOptions,
  });

  const [remove] = ProjectEntity.model.useDelete();
  const useGetAllTenants = TenantEntity.model.useGetAll;

  const refetch = useCallback(
    (variableOptions?: Partial<GetProjectsQueryVariables>) => {
      if (!variableOptions?.filter) {
        variableOptions.filter = {};
      }

      return originalRefetch(merge({}, defaultVariableOptions, variableOptions));
    },
    [defaultVariableOptions, originalRefetch]
  );

  const generateFilterForTenants = (value: string) => {
    return {
      filter: {
        tenants: {
          id: {
            eq: value,
          },
        },
      },
    };
  };

  const allActions = useMemo(
    () => ({ loading, refetch, remove, useGetAllTenants, generateFilterForTenants }),
    [loading, refetch, remove, useGetAllTenants, generateFilterForTenants]
  );
  const data = useMemo(
    () => (projectData ? { totalCount: projectData.projects.totalCount, rows: items } : { totalCount: 0, rows: [] }),
    [projectData, items]
  );

  return { data, actions: allActions, refetch, defaultVariableOptions, variables };
};

export default function ProjectsTable({ noWrapper }: { noWrapper?: boolean }) {
  const props = useProjectsTable();

  return <EntityTable {...props} Entity={ProjectEntity} noWrapper={noWrapper} />;
}
