import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormUpdateProps } from '@boilerplate/types/entity';
import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import Notistack from '@/lib/notistack';
import ProjectEntity from '@/entities/project';
import { catchGraphQlError } from '@/lib/catchError';

  import CustomerEntity from '@/entities/customer';
  import CategoryEntity from '@/entities/category';
  import TenantEntity from '@/entities/tenant';
  import LoggedHourEntity from '@/entities/loggedHour';
  import BudgetEntity from '@/entities/budget';
  import PlannedHourEntity from '@/entities/plannedHour';
  import HoursRequiredEntity from '@/entities/hoursRequired';
  import InvoiceEntity from '@/entities/invoice';
  import ProjectDateEntity from '@/entities/projectDate';
  import ContractEntity from '@/entities/contract';
  import ProjectDocumentEntity from '@/entities/projectDocument';
  import UserTenantRoleEntity from '@/entities/userTenantRole';
  import RegulationEntity from '@/entities/regulation';
  import UserEntity from '@/entities/user';

function ProjectBaseUpdateForm({ formOnly = false, onClose, Entity = ProjectEntity, id: propId, ...props }: BaseFormUpdateProps<typeof ProjectEntity.model>) {
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const id = propId || paramId;

  const Component = formOnly ? EntityForm : EntityFormPage;

  const { loading, data } = ProjectEntity.model.useGet({
    variables: {
      id,
            withCategories: true,
            withTenants: true,
            withRegulation: true,
            withResponsibleUser: true,
            withControlUser: true,
    },
  });

  const [updateMutation, { loading: updateLoading }] = ProjectEntity.model.useUpdate();

  const relationsOptionsQuery = ProjectEntity.model.useRelationsOptions({
    variables: {
          withCategories: true,
          withTenants: true,
          withRegulations: true,
          withResponsibleUsers: true,
          withControlUsers: true,
    }
  });

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(relationsOptionsQuery, [
              CategoryEntity,
              TenantEntity,
              RegulationEntity,
              UserEntity,
              UserEntity,
      ]),
    [relationsOptionsQuery]
  );

  const handleSubmit = (newData) => {
    newData.variables = getGraphqlData(Entity, newData.variables, 'update');

    updateMutation(newData)
      .then((result) => {
        Notistack.toast(t('crud:updatedItem', { item: t('entities:project') }), { variant: 'success' });

        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.updateProject);
      })
      .catch(catchGraphQlError);
  };

  if (loading || relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="update"
      Entity={Entity}
      loading={updateLoading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptions}
      data={data.project}
    />
  );
}

export default ProjectBaseUpdateForm;
