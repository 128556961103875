import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const ProjectBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:project.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'name',
        label: t(['entityFields:project.name', 'entityFields:default.name'], 'name') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'active',
        label: t(['entityFields:project.active', 'entityFields:default.active'], 'active') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
      },
      {
          field: 'description',
        label: t(['entityFields:project.description', 'entityFields:default.description'], 'description') || undefined,
          cell: CellRenderers['LongTextRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['LongTextFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'branch',
        label: t(['entityFields:project.branch', 'entityFields:default.branch'], 'branch') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
        optional: true,
      },
      {
          field: 'subsidyPercentage',
        label: t(['entityFields:project.subsidy_percentage', 'entityFields:default.subsidy_percentage'], 'subsidyPercentage') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
                    sortable: true,
          numeric: true,
      },
      {
          field: 'invoiceVatSubsidyEligible',
        label: t(['entityFields:project.invoice_vat_subsidy_eligible', 'entityFields:default.invoice_vat_subsidy_eligible'], 'invoiceVatSubsidyEligible') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
      },
      {
          field: 'reference',
        label: t(['entityFields:project.reference', 'entityFields:default.reference'], 'reference') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
        optional: true,
      },
      {
          field: 'status',
        label: t(['entityFields:project.status', 'entityFields:default.status'], 'status') || undefined,
          cell: CellRenderers['SelectRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['SelectFilter'],
            sortable: true,
        filterOptions: [
          'concept',
          'submitted',
          'granted',
          'ongoing',
          'finalizationRequested',
          'finalized',
        ],
      },
      {
          field: 'hourSubsidyType',
        label: t(['entityFields:project.hour_subsidy_type', 'entityFields:default.hour_subsidy_type'], 'hourSubsidyType') || undefined,
          cell: CellRenderers['SelectRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['SelectFilter'],
            sortable: true,
        filterOptions: [
          'actualRate',
          'fixedSurcharge',
          'fixedRate',
        ],
      },
      {
          field: 'maxHourlyRate',
        label: t(['entityFields:project.max_hourly_rate', 'entityFields:default.max_hourly_rate'], 'maxHourlyRate') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
                    sortable: true,
          numeric: true,
        optional: true,
      },
      {
          field: 'addendum',
        label: t(['entityFields:project.addendum', 'entityFields:default.addendum'], 'addendum') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
      },
      {
          field: 'startDate',
        label: t(['entityFields:project.start_date', 'entityFields:default.start_date'], 'startDate') || undefined,
          cell: CellRenderers['DateRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'endDate',
        label: t(['entityFields:project.end_date', 'entityFields:default.end_date'], 'endDate') || undefined,
          cell: CellRenderers['DateRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'filingDate',
        label: t(['entityFields:project.filing_date', 'entityFields:default.filing_date'], 'filingDate') || undefined,
          cell: CellRenderers['DateRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'decisionDate',
        label: t(['entityFields:project.decision_date', 'entityFields:default.decision_date'], 'decisionDate') || undefined,
          cell: CellRenderers['DateRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'tenants',
        label: t(['entityFields:project.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'projectDates',
        label: t(['entityFields:project.project_date', 'entityFields:default.project_date'], 'projectDate') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'projectDocuments',
        label: t(['entityFields:project.project_document', 'entityFields:default.project_document'], 'projectDocument') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'regulation',
        label: t(['entityFields:project.regulation', 'entityFields:default.regulation'], 'regulation') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'responsibleUser',
        label: t(['entityFields:project.responsible_user', 'entityFields:default.responsible_user'], 'responsibleUser') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'controlUser',
        label: t(['entityFields:project.control_user', 'entityFields:default.control_user'], 'controlUser') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:project.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:project.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const ProjectBaseTableSchema: TableSchema[] = [
  ...ProjectBaseTableSchemaWithoutActions,
  actions,
];

export { actions, ProjectBaseTableSchemaWithoutActions };
export default ProjectBaseTableSchema;
