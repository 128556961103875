const schema = [
        ['machineName'],
        ['displayName'],
        ['description'],
        ['permissions'],
        ['projectDateReminders'],
        ['order'],
];

export default schema;
