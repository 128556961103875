import { TableChart as TableChartIcon, AccountCircle as AccountCircleIcon, DateRange as DateRangeIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

import DefaultHeader from '@boilerplate/components/app/Layout/Header/DefaultHeader';
import { hasPermission, setTenant, useUserStore } from '@/stores/UserStore';

function Header() {
  const user = useUserStore();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleAdminClick = () => {
    if (user?.isAuthenticated && user.isSuperAdmin) {
      // Select all tenants by default
      setTenant(undefined).catch(console.error);
    }
  };

  return (
    <DefaultHeader hideTenantMenu={pathname === '/'}>
      <MenuItem component={Link} to="/profile">
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        {t('headerLinks:link.profile')}
      </MenuItem>

      {hasPermission('admin-dashboard', user) && (
        <MenuItem component={Link} to="/admin/dashboard" onClick={handleAdminClick}>
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          {t('headerLinks:link.admin')}
        </MenuItem>
      )}
    </DefaultHeader>
  );
}

export default Header;
