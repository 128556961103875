import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        description: {
          type: String,
          form: {
            component: 'LongText',
          },
          view: true,
        },
        startDatetime: {
          type: Date,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        endDatetime: {
          type: Date,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        break: {
          type: SimpleSchema.Integer,
          form: {
            component: 'Number',
          },
          view: true,
        },
        declarableTime: {
          type: SimpleSchema.Integer,
          optional: true,
          form: {
            component: 'Number',
          },
          view: true,
        },
        addendum: {
          type: Boolean,
          defaultValue: false,
          optional: true,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        status: {
          type: Boolean,
          optional: true,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        category: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'category',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        project: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'project',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        user: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          form: true,
          view: true,
        },
        approvedBy: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          optional: true,
          form: false,
          view: true,
        },
        tenant: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'tenant',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:loggedHour.id', 'entityFields:default.id'], '') || undefined,
        description: t(['entityFields:loggedHour.description', 'entityFields:default.description'], '') || undefined,
        startDatetime: t(['entityFields:loggedHour.start_datetime', 'entityFields:default.start_datetime'], '') || undefined,
        endDatetime: t(['entityFields:loggedHour.end_datetime', 'entityFields:default.end_datetime'], '') || undefined,
        break: t(['entityFields:loggedHour.break', 'entityFields:default.break'], '') || undefined,
        declarableTime: t(['entityFields:loggedHour.declarable_time', 'entityFields:default.declarable_time'], '') || undefined,
        addendum: t(['entityFields:loggedHour.addendum', 'entityFields:default.addendum'], '') || undefined,
        status: t(['entityFields:loggedHour.status', 'entityFields:default.status'], '') || undefined,
        category: t(['entityFields:loggedHour.category', 'entityFields:default.category'], '') || undefined,
        project: t(['entityFields:loggedHour.project', 'entityFields:default.project'], '') || undefined,
        user: t(['entityFields:loggedHour.user', 'entityFields:default.user'], '') || undefined,
        approvedBy: t(['entityFields:loggedHour.approved_by', 'entityFields:default.approved_by'], '') || undefined,
        tenant: t(['entityFields:loggedHour.tenant', 'entityFields:default.tenant'], '') || undefined,
        createdAt: t(['entityFields:loggedHour.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:loggedHour.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:loggedHour.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;