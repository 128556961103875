const schema = [
        ['hoursPerWeek'],
        ['costPerHour'],
        ['startDate'],
        ['endDate'],
        ['customCostPerHour'],
        ['salarySlip'],
        ['user'],
        ['tenant'],
        ['project'],
];

export default schema;
