import { merge } from 'lodash';
import React, { useMemo, useCallback } from 'react';

import { RegulationSortFields, SortDirection, GetRegulationsQueryVariables } from '@/graphql';
import { BaseTableProps } from '@boilerplate/types/entity';
import Entity from '@/entities/regulation';
import EntityTable, {
  defaultPage,
  defaultPageSize,
  defaultSortOrder,
  getDefaultSortField,
} from '@boilerplate/components/entity/EntityTable/EntityTable';
import { canPerformAction, useAuthenticatedUserStore } from '@/stores/UserStore';
import { openConfirmationDialog } from '@/stores/ConfirmationDialogStore';


export const useRegulationsTable = ({ queryOptions }: { queryOptions?: GetRegulationsQueryVariables } = {}) => {
  const defaultVariableOptions = useMemo<GetRegulationsQueryVariables>(
    () => 
      merge(
        {
          sorting: {
            direction: defaultSortOrder === 'desc' ? SortDirection.Desc : SortDirection.Asc,
            field: getDefaultSortField(Entity.table.schema) as RegulationSortFields,
          },
          paging: {
            page: defaultPage,
            pageSize: defaultPageSize,
          },
                  withProjects: true,
          },
          queryOptions,
        ), 
    [queryOptions]
    );
  
  const {
    data: regulationData,
    items = [],
    loading,
    refetch: originalRefetch,
    variables,
  } = Entity.model.useGetAll({
    fetchPolicy: 'cache-and-network',
    variables: defaultVariableOptions,
  });

  const [remove] = Entity.model.useDelete();

  const refetch = useCallback(
    (variableOptions?: Partial<GetRegulationsQueryVariables >) => {
      return originalRefetch(merge({}, defaultVariableOptions, variableOptions));
    },
    [defaultVariableOptions, originalRefetch]
  );

  const allActions = useMemo(() => ({ loading, refetch, remove }), [loading, refetch, remove]);
  const data = useMemo(
    () =>
      regulationData
        ? { totalCount: regulationData.regulations.totalCount, rows: items }
        : { totalCount: 0, rows: [] },
    [regulationData, items]
  );

  return { data, actions: allActions, refetch, defaultVariableOptions, variables };
}

export type RegulationsBaseTableProps = BaseTableProps<typeof Entity.model>;

export default function RegulationsBaseTable(props: RegulationsBaseTableProps) {
  const tableProps = useRegulationsTable();
  const authentictedUser = useAuthenticatedUserStore();

  return (
    <EntityTable
      {...props}
      {...tableProps}
      Entity={Entity}
      user={authentictedUser}
      canPerformThisAction={canPerformAction}
      openConfirmationDialog={openConfirmationDialog}
    />
  );
}
