import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        user: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          form: true,
          view: true,
        },
        tenant: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'tenant',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        role: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'role',
            displayField: 'displayName',
          },
          form: true,
          view: true,
        },
        project: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'project',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:userTenantRole.id', 'entityFields:default.id'], '') || undefined,
        user: t(['entityFields:userTenantRole.user', 'entityFields:default.user'], '') || undefined,
        tenant: t(['entityFields:userTenantRole.tenant', 'entityFields:default.tenant'], '') || undefined,
        role: t(['entityFields:userTenantRole.role', 'entityFields:default.role'], '') || undefined,
        project: t(['entityFields:userTenantRole.project', 'entityFields:default.project'], '') || undefined,
});

export default schema;