import { keyBy, merge } from 'lodash';
import React, { useMemo, useCallback } from 'react';

import { LoggedHourSortFields, SortDirection, GetLoggedHoursQueryVariables } from '@/graphql';
import { BaseTableProps } from '@boilerplate/types/entity';
import Entity from '@/entities/loggedHour';
import EntityTable, {
  defaultPage,
  defaultPageSize,
  defaultSortOrder,
  getDefaultSortField,
} from '@boilerplate/components/entity/EntityTable/EntityTable';
import { canPerformAction, useAuthenticatedUserStore } from '@/stores/UserStore';
import { openConfirmationDialog } from '@/stores/ConfirmationDialogStore';
import CategoryEntity from '@/entities/category';

export const useLoggedHoursTable = ({ queryOptions }: { queryOptions?: GetLoggedHoursQueryVariables } = {}) => {
  const defaultVariableOptions = useMemo<GetLoggedHoursQueryVariables>(
    () =>
      merge(
        {
          sorting: {
            direction: defaultSortOrder === 'desc' ? SortDirection.Desc : SortDirection.Asc,
            field: getDefaultSortField(Entity.table.schema) as LoggedHourSortFields,
          },
          paging: {
            page: defaultPage,
            pageSize: defaultPageSize,
          },
          withCategory: true,
          withProject: true,
          withUser: true,
          withApprovedBy: true,
          withTenant: true,
        },
        queryOptions
      ),
    [queryOptions]
  );

  const {
    data: loggedHourData,
    items = [],
    loading,
    refetch: originalRefetch,
    variables,
  } = Entity.model.useGetAll({
    fetchPolicy: 'cache-and-network',
    variables: defaultVariableOptions,
  });

  const { data: categories, loading: loadingCategories } = CategoryEntity.model.useGetAll();
  const categoriesById = useMemo(() => keyBy(categories?.categories?.items, 'id'), [categories]);

  const [remove] = Entity.model.useDelete();

  const refetch = useCallback(
    (variableOptions?: Partial<GetLoggedHoursQueryVariables>) => {
      return originalRefetch(merge({}, defaultVariableOptions, variableOptions));
    },
    [defaultVariableOptions, originalRefetch]
  );

  const allActions = useMemo(() => ({ loading: loading || loadingCategories, refetch, remove }), [loading, loadingCategories, refetch, remove]);
  const data = useMemo(
    () =>
      loggedHourData
        ? {
            totalCount: loggedHourData.loggedHours.totalCount,
            rows: items.map((item) => ({
              ...item,
              parentCategory: categoriesById[item.category?.parentCategoryId]?.name ?? '-',
            })),
          }
        : { totalCount: 0, rows: [] },
    [loggedHourData, items, categoriesById]
  );

  return {
    data,
    actions: allActions,
    refetch,
    defaultVariableOptions,
    variables,
    mapQueryBuilder: {
      parentCategory: (value) => ({
        ['category']: {
          parentCategory: {
            name: { like: `%${value}%` },
          },
        },
      }),
    },
  };
};

export type LoggedHoursTableProps = BaseTableProps<typeof Entity.model>;

export default function LoggedHoursTable(props: LoggedHoursTableProps) {
  const tableProps = useLoggedHoursTable();
  const authentictedUser = useAuthenticatedUserStore();

  return (
    <EntityTable
      {...props}
      {...tableProps}
      Entity={Entity}
      user={authentictedUser}
      canPerformThisAction={canPerformAction}
      openConfirmationDialog={openConfirmationDialog}
    />
  );
}
