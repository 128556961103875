import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const LoggedHourBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:loggedHour.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'description',
        label: t(['entityFields:loggedHour.description', 'entityFields:default.description'], 'description') || undefined,
          cell: CellRenderers['LongTextRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['LongTextFilter'],
            sortable: true,
      },
      {
          field: 'startDatetime',
        label: t(['entityFields:loggedHour.start_datetime', 'entityFields:default.start_datetime'], 'startDatetime') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
      },
      {
          field: 'endDatetime',
        label: t(['entityFields:loggedHour.end_datetime', 'entityFields:default.end_datetime'], 'endDatetime') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
      },
      {
          field: 'break',
        label: t(['entityFields:loggedHour.break', 'entityFields:default.break'], 'break') || undefined,
          cell: CellRenderers['NumberRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
            sortable: true,
      },
      {
          field: 'declarableTime',
        label: t(['entityFields:loggedHour.declarable_time', 'entityFields:default.declarable_time'], 'declarableTime') || undefined,
          cell: CellRenderers['NumberRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'addendum',
        label: t(['entityFields:loggedHour.addendum', 'entityFields:default.addendum'], 'addendum') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'status',
        label: t(['entityFields:loggedHour.status', 'entityFields:default.status'], 'status') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'category',
        label: t(['entityFields:loggedHour.category', 'entityFields:default.category'], 'category') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'project',
        label: t(['entityFields:loggedHour.project', 'entityFields:default.project'], 'project') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'user',
        label: t(['entityFields:loggedHour.user', 'entityFields:default.user'], 'user') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'approvedBy',
        label: t(['entityFields:loggedHour.approved_by', 'entityFields:default.approved_by'], 'approvedBy') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'tenant',
        label: t(['entityFields:loggedHour.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:loggedHour.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:loggedHour.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const LoggedHourBaseTableSchema: TableSchema[] = [
  ...LoggedHourBaseTableSchemaWithoutActions,
  actions,
];

export { actions, LoggedHourBaseTableSchemaWithoutActions };
export default LoggedHourBaseTableSchema;
