import { ErrorOutline as ErrorIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { darken, lighten } from '@mui/system';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import PageHeading from './PageHeading';

export type PageErrorProps = {
  noBack?: boolean;
  children: Error;
  title?: string;
};

const useStyles = makeStyles()(({ palette, spacing, shape }) => {
  const getBackgroundColor = palette.mode === 'light' ? lighten : darken;
  const getColor = palette.mode === 'light' ? darken : lighten;

  return {
    container: {
      gridTemplateRows: 'auto 1fr',
      display: 'grid',
      height: '100%',
    },
    messageContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    message: {
      backgroundColor: getBackgroundColor(palette.error.light, 0.9),
      color: getColor(palette.error.light, 0.6),
      borderRadius: shape.borderRadius,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: spacing(6),
      gap: spacing(2),
      maxHeight: 1200,
      display: 'flex',
      maxWidth: 1200,
      height: '80%',
      width: '80%',

      '&>svg': {
        fontSize: '6rem',
      },
      '&>h2': {
        fontSize: '4rem',
      },
      '&>p': {
        fontSize: '1.5rem',
      },
    },
  };
});

function PageError({ children, title, noBack = false }: PageErrorProps): JSX.Element {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <PageHeading title={title} arrowBack={!noBack} />

      <div className={classes.messageContainer}>
        <div className={classes.message}>
          <ErrorIcon fontSize="large" />

          <Typography variant="h2" gutterBottom>
            {children.name}
          </Typography>

          <Typography>{children.message}</Typography>
        </div>
      </div>
    </div>
  );
}

export default PageError;
