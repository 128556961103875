import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetRegulationDocument,
  GetRegulationQuery,
  GetRegulationQueryVariables,
  GetRegulationsDocument,
  GetRegulationsQuery,
  GetRegulationsQueryVariables,
  useGetRegulationQuery,
  useGetRegulationLazyQuery,
  useGetRegulationsQuery,
  useGetRegulationsLazyQuery,

    useGetAllRelatedDataForRegulationQuery,
    useGetAllRelatedDataForRegulationLazyQuery,
    GetAllRelatedDataForRegulationQuery,
    GetAllRelatedDataForRegulationQueryVariables,

  CreateRegulationDocument,
  CreateRegulationMutation,
  CreateRegulationMutationVariables,
  useCreateRegulationMutation,

  DeleteRegulationDocument,
  DeleteRegulationMutation,
  DeleteRegulationMutationVariables,
  useDeleteRegulationMutation,

  UpdateRegulationDocument,
  UpdateRegulationMutation,
  UpdateRegulationMutationVariables,
  useUpdateRegulationMutation,

    useCreatedRegulationSubscription,
    useUpdatedRegulationSubscription,
    useDeletedRegulationSubscription,
    useRestoredRegulationSubscription,
} from '@/graphql';

  type RegulationCollection = Dictionary<NonNullable<GetRegulationsQuery['regulations']['items']>[number]>;

const RegulationBaseModel = {
  get: (options: Omit<QueryOptions<GetRegulationQueryVariables, GetRegulationQuery>, 'query'>) => {
    return apolloClient.query<GetRegulationQuery, GetRegulationQueryVariables>({
      ...options,
      query: GetRegulationDocument,
    })
    .then(({ data }) => data.regulation);
  },

  useGet: useGetRegulationQuery,

  getAll: (options?: Omit<QueryOptions<GetRegulationsQueryVariables, GetRegulationsQuery>, 'query'>) => {
    return apolloClient
      .query<GetRegulationsQuery, GetRegulationsQueryVariables>({
        ...options,
        query: GetRegulationsDocument
      })
      .then(({ data }) => data.regulations.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetRegulationsQuery, GetRegulationsQueryVariables>) => {
    const hookResult = useGetRegulationsQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.regulations?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForRegulationQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForRegulationQuery, GetAllRelatedDataForRegulationQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForRegulationQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetRegulationLazyQuery,

  useGetAllLazy: useGetRegulationsLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForRegulationLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateRegulationMutation, CreateRegulationMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateRegulationMutation, CreateRegulationMutationVariables>({
      ...options,
      mutation: CreateRegulationDocument,
    });
  },

  useCreate: useCreateRegulationMutation,

  update: (options: Omit<MutationOptions<UpdateRegulationMutation, UpdateRegulationMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateRegulationMutation, UpdateRegulationMutationVariables>({
      ...options,
      mutation: UpdateRegulationDocument,
    });
  },

  useUpdate: useUpdateRegulationMutation,

  delete: (options: Omit<MutationOptions<DeleteRegulationMutation, DeleteRegulationMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteRegulationMutation, DeleteRegulationMutationVariables>({
      ...options,
      mutation: DeleteRegulationDocument,
    });
  },

  useDelete: useDeleteRegulationMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetRegulationsQuery, GetRegulationsQueryVariables>) => {
      const [collection, setCollection] = useState<RegulationCollection>({});

      const { items, loading, error, refetch } = RegulationBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedRegulationSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdRegulation?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdRegulation.id]: data.createdRegulation,
            }));
          }
        },
      });

      useUpdatedRegulationSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedRegulation?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedRegulation.id]: data.updatedRegulation,
            }));
          }
        },
      });

      useDeletedRegulationSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedRegulation?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedRegulation.id];

              return newCollection;
            });
          }
        },
      });


      return { collection, loading, error, refetch };
    },
};

export default RegulationBaseModel;
