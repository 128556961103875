// Implementation from https://github1s.com/remix-run/react-router/blob/HEAD/packages/react-router-dom/index.tsx#L133-L137
import { History } from 'history';
import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

export interface BrowserRouterProps {
  basename?: string;
  children?: ReactNode;
  history: History;
}

// This component should do the same as React Router's BrowserRouter
//  But the BrowserRouter component has a bug/feature where it does not listen to changes made using window.history.pushState
//  This component does allow outside changes to the history state
export function HistoryRouter({ basename, children, history }: BrowserRouterProps) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  );
}
