import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const ProjectDateReminderBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:projectDateReminder.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'advanceHours',
        label: t(['entityFields:projectDateReminder.advance_hours', 'entityFields:default.advance_hours'], 'advanceHours') || undefined,
          cell: CellRenderers['NumberRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
            sortable: true,
      },
      {
          field: 'projectDate',
        label: t(['entityFields:projectDateReminder.project_date', 'entityFields:default.project_date'], 'projectDate') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'roles',
        label: t(['entityFields:projectDateReminder.role', 'entityFields:default.role'], 'role') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'sentAt',
        label: t(['entityFields:projectDateReminder.sent_at', 'entityFields:default.sent_at'], 'sentAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:projectDateReminder.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:projectDateReminder.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const ProjectDateReminderBaseTableSchema: TableSchema[] = [
  ...ProjectDateReminderBaseTableSchemaWithoutActions,
  actions,
];

export { actions, ProjectDateReminderBaseTableSchemaWithoutActions };
export default ProjectDateReminderBaseTableSchema;
