const schema = [
        ['name'],
        ['timeZone'],
        ['description'],
        ['kvk'],
        ['iban'],
        ['invoiceDetails'],
        ['contactPerson'],
        ['invoiceVatSubsidyEligible'],
        ['mfaRequired'],
        ['projects'],
];

export default schema;
