import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import { PreFilledFields } from '@boilerplate/types/entity';
import { uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import AvailabilityEntity from '@/entities/availability';
import ContractEntity from '@/entities/contract';
import DaysOffEntity from '@/entities/daysOff';
import LoggedHourEntity from '@/entities/loggedHour';
import PlannedHourEntity from '@/entities/plannedHour';
import ProductivityEntity from '@/entities/productivity';
import ProjectEntity from '@/entities/project';
import RoleEntity from '@/entities/role';
import TenantEntity from '@/entities/tenant';
import UserEntity from '@/entities/user';
import { UserTenantRole } from '@/graphql';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

export default (values?: { tenant?: string; project?: string; preFilledFields?: PreFilledFields<UserTenantRole> }) => {
  const currentUser = useAuthenticatedUserStore();

  const relationsOptionsQuery = UserEntity.model.useRelationsOptions();

  const preFilledProjectId = useMemo(() => {
    return values?.preFilledFields?.project?.value as string;
  }, [values?.preFilledFields?.project?.value]);

  const tenantFilter = useMemo(() => {
    if (preFilledProjectId) {
      return { projects: { id: { eq: preFilledProjectId } } };
    }

    return undefined;
  }, [preFilledProjectId]);

  const rolesQuery = RoleEntity.model.useGetAll({
    variables: {
      filter: {
        machineName: { notIn: currentUser.isSuperAdmin ? [''] : ['superAdmin'] },
      },
    },
  });

  const tenantQuery = TenantEntity.model.useGetAll({
    variables: {
      withProjects: true,
      filter: tenantFilter,
    },
  });

  const rolesRelationOptions = useMemo(
    () => ({
      roles: rolesQuery.items.sort((a, b) => b.order - a.order).map((item) => ({ value: item.id, label: item.displayName })), // reverse order
    }),
    [rolesQuery.items]
  );

  const projectRelationOptions = useMemo(() => {
    let projects = tenantQuery.items.flatMap((tenant) => tenant.projects?.items ?? []);

    if (preFilledProjectId) {
      projects = projects.filter((item) => item.id === preFilledProjectId);
    } else if (values?.tenant) {
      projects = tenantQuery.items.find((tenant) => tenant.id === values.tenant)?.projects?.items ?? [];
    }

    return {
      projects: uniqBy(
        projects.map((item) => ({ value: item.id, label: item.name })),
        'value'
      ),
    };
  }, [tenantQuery.items, values?.tenant, preFilledProjectId]);

  const tenantRelationOptions = useMemo(
    () => ({ tenants: tenantQuery.items.map((item) => ({ value: item.id, label: item.name })) }),
    [tenantQuery.items]
  );

  const handleRefetch = useCallback(
    () => Promise.all([relationsOptionsQuery.refetch(), rolesQuery.refetch(), tenantQuery.refetch()]),
    [relationsOptionsQuery, rolesQuery, tenantQuery]
  );

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(
        {
          items: {
            ...relationsOptionsQuery.items,
            ...rolesRelationOptions,
            ...projectRelationOptions,
            ...tenantRelationOptions,
          },
          refetch: handleRefetch,
        },
        [
          ContractEntity,
          AvailabilityEntity,
          DaysOffEntity,
          LoggedHourEntity,
          PlannedHourEntity,
          ProductivityEntity,
          RoleEntity,
          ProjectEntity,
          TenantEntity,
        ]
      ),
    [handleRefetch, relationsOptionsQuery.items, rolesRelationOptions, projectRelationOptions, tenantRelationOptions]
  );

  return useMemo(
    () => ({
      loading: relationsOptionsQuery.loading || rolesQuery.loading || tenantQuery.loading,
      error: relationsOptionsQuery.error || rolesQuery.error || tenantQuery.error,
      data: relationsOptions,
    }),
    [
      relationsOptions,
      relationsOptionsQuery.error,
      relationsOptionsQuery.loading,
      rolesQuery.loading,
      rolesQuery.error,
      tenantQuery.loading,
      tenantQuery.error,
    ]
  );
};
