import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        advanceHours: {
          type: SimpleSchema.Integer,
          form: {
            component: 'Number',
          },
          view: true,
        },
        mailText: {
          type: String,
          form: {
            component: 'RichText',
          },
          view: true,
        },
        projectDate: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'projectDate',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        roles: {
          type: Array,
          relation: {
            name: 'BelongsToMany',
            type: 'ManyToMany',
            model: 'role',
            displayField: 'displayName',
          },
          optional: true,
          form: true,
          view: true,
        },
        'roles.$': {
          type: String,
          optional: true,
        },
        sentAt: {
          type: Date,
          optional: true,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:projectDateReminder.id', 'entityFields:default.id'], '') || undefined,
        advanceHours: t(['entityFields:projectDateReminder.advance_hours', 'entityFields:default.advance_hours'], '') || undefined,
        mailText: t(['entityFields:projectDateReminder.mail_text', 'entityFields:default.mail_text'], '') || undefined,
        projectDate: t(['entityFields:projectDateReminder.project_date', 'entityFields:default.project_date'], '') || undefined,
        roles: t(['entityFields:projectDateReminder.role', 'entityFields:default.role'], '') || undefined,
        sentAt: t(['entityFields:projectDateReminder.sent_at', 'entityFields:default.sent_at'], '') || undefined,
        createdAt: t(['entityFields:projectDateReminder.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:projectDateReminder.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:projectDateReminder.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;