import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        startDate: {
          type: Date,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        factor: {
          type: Number,
          form: true,
          view: true,
        },
        user: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          form: true,
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:productivity.id', 'entityFields:default.id'], '') || undefined,
        startDate: t(['entityFields:productivity.start_date', 'entityFields:default.start_date'], '') || undefined,
        factor: t(['entityFields:productivity.factor', 'entityFields:default.factor'], '') || undefined,
        user: t(['entityFields:productivity.user', 'entityFields:default.user'], '') || undefined,
        createdAt: t(['entityFields:productivity.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:productivity.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:productivity.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;