import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const RoleBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:role.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'machineName',
        label: t(['entityFields:role.machine_name', 'entityFields:default.machine_name'], 'machineName') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'displayName',
        label: t(['entityFields:role.display_name', 'entityFields:default.display_name'], 'displayName') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'description',
        label: t(['entityFields:role.description', 'entityFields:default.description'], 'description') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'permissions',
        label: t(['entityFields:role.permission', 'entityFields:default.permission'], 'permission') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'projectDateReminders',
        label: t(['entityFields:role.project_date_reminder', 'entityFields:default.project_date_reminder'], 'projectDateReminder') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'order',
        label: t(['entityFields:role.order', 'entityFields:default.order'], 'order') || undefined,
          cell: CellRenderers['NumberRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
            sortable: true,
      },
];

const RoleBaseTableSchema: TableSchema[] = [
  ...RoleBaseTableSchemaWithoutActions,
  actions,
];

export { actions, RoleBaseTableSchemaWithoutActions };
export default RoleBaseTableSchema;
