import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        subsidyPercentage: {
          type: Number,
          optional: true,
          form: true,
          view: true,
        },
        hasHours: {
          type: Boolean,
          defaultValue: true,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        hasInvoices: {
          type: Boolean,
          defaultValue: true,
          form: {
            component: 'Boolean',
          },
          view: true,
        },
        order: {
          type: SimpleSchema.Integer,
          defaultValue: 0,
          form: {
            component: 'Number',
          },
          view: true,
        },
        loggedHours: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'loggedHour',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'loggedHours.$': {
          type: String,
          optional: true,
        },
        budgetLines: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'budgetLine',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'budgetLines.$': {
          type: String,
          optional: true,
        },
        invoices: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'invoice',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'invoices.$': {
          type: String,
          optional: true,
        },
        projects: {
          type: Array,
          relation: {
            name: 'BelongsToMany',
            type: 'ManyToMany',
            model: 'project',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        'projects.$': {
          type: String,
          optional: true,
        },
        parentCategory: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'category',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        subCategories: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'category',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'subCategories.$': {
          type: String,
          optional: true,
        },
        users: {
          type: Array,
          relation: {
            name: 'BelongsToMany',
            type: 'ManyToMany',
            model: 'user',
            displayField: 'fullName',
          },
          optional: true,
          form: true,
          view: true,
        },
        'users.$': {
          type: String,
          optional: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:category.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:category.name', 'entityFields:default.name'], '') || undefined,
        subsidyPercentage: t(['entityFields:category.subsidy_percentage', 'entityFields:default.subsidy_percentage'], '') || undefined,
        hasHours: t(['entityFields:category.has_hours', 'entityFields:default.has_hours'], '') || undefined,
        hasInvoices: t(['entityFields:category.has_invoices', 'entityFields:default.has_invoices'], '') || undefined,
        order: t(['entityFields:category.order', 'entityFields:default.order'], '') || undefined,
        loggedHours: t(['entityFields:category.logged_hour', 'entityFields:default.logged_hour'], '') || undefined,
        budgetLines: t(['entityFields:category.budget_line', 'entityFields:default.budget_line'], '') || undefined,
        invoices: t(['entityFields:category.invoice', 'entityFields:default.invoice'], '') || undefined,
        projects: t(['entityFields:category.project', 'entityFields:default.project'], '') || undefined,
        parentCategory: t(['entityFields:category.parent_category', 'entityFields:default.parent_category'], '') || undefined,
        subCategories: t(['entityFields:category.sub_categories', 'entityFields:default.sub_categories'], '') || undefined,
        users: t(['entityFields:category.user', 'entityFields:default.user'], '') || undefined,
        createdAt: t(['entityFields:category.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:category.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:category.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;