import customActions from '@boilerplate/lib/customActions';
import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';
import { getI18n } from 'react-i18next';

import UserBaseSimpleSchema from '@/_base/user/UserBaseSimpleSchema';
import { UserBaseTableSchemaWithoutActions } from '@/_base/user/UserBaseTableSchema';
import { CellRenderers, ColumnFilters } from '@/components/entity';

import ActionButtons from './ActionButtons';
import UserInsertForm from './UserInsertForm';
import UserModel from './UserModel';
import UserUpdateForm from './UserUpdateForm';
import UserView from './UserView';

import UsersBaseTable from '@/_base/user/components/UserBaseTable';
import UsersTable from './UserTable';

const { t } = getI18n();

const OrderSchema = [
  ['name', 'lastName'],
  ['email', 'phoneNumber'],
  ['function'],
  ['note'],
  ['active'],
  ['role'],
  ['tenant'],
  ['project'],
  ['invite'],
];

const idIndex = UserBaseTableSchemaWithoutActions.findIndex((item) => item.field === 'id');
const nameIndex = UserBaseTableSchemaWithoutActions.findIndex((item) => item.field === 'name');
const lastNameIndex = UserBaseTableSchemaWithoutActions.findIndex((item) => item.field === 'lastName');

UserBaseTableSchemaWithoutActions.splice(
  nameIndex + 1, // Set lastName after name
  0,
  UserBaseTableSchemaWithoutActions.splice(lastNameIndex, 1)[0]
);

UserBaseTableSchemaWithoutActions.splice(
  idIndex + 1, // Set tenants after ID
  0,
  {
    field: 'tenants',
    label: t(['entityFields:userTenantRole.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
    cell: CellRenderers.AutoRenderer,
    filter: ColumnFilters.TeamFilter,
    sortable: false,
  }
);

const UserEntity: Entity<typeof UserModel> = {
  name: 'user',

  simpleSchema: UserBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(UserBaseSimpleSchema).extend({
      role: {
        type: String,
        label: t(['entityFields:user.role']),
        relation: {
          name: 'ManyToOne',
          type: 'ManyToOne',
          model: 'role',
        },
        form: true,
      },
      tenant: {
        type: String,
        label: t(['entityFields:user.tenant']),
        relation: {
          name: 'ManyToOne',
          type: 'ManyToOne',
          model: 'tenant',
        },
        form: true,
      },
      project: {
        type: String,
        label: t(['entityFields:user.project']),
        relation: {
          name: 'ManyToOne',
          type: 'ManyToOne',
          model: 'project',
        },
        form: true,
      },
      invite: {
        type: Boolean,
        label: t(['entityFields:user.invite']),
        form: true,
        required: false,
      },
    }),
    orderSchema: OrderSchema,
    component: UserInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(UserBaseSimpleSchema),
    orderSchema: OrderSchema,
    component: UserUpdateForm,
  },

  table: {
    schema: [...UserBaseTableSchemaWithoutActions, customActions(ActionButtons, t)],
    component: UsersTable,
  },

  view: {
    component: UserView,
  },

  model: UserModel,

  routes: {},

  settings: {
    displayField: 'name',
  },
};

export default UserEntity;
