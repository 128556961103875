import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        startDate: {
          type: Date,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        endDate: {
          type: Date,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        approved: {
          type: Boolean,
          optional: true,
          form: {
            component: 'Approve',
          },
          view: true,
        },
        note: {
          type: String,
          optional: true,
          form: true,
          view: true,
        },
        user: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          form: true,
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:daysOff.id', 'entityFields:default.id'], '') || undefined,
        startDate: t(['entityFields:daysOff.start_date', 'entityFields:default.start_date'], '') || undefined,
        endDate: t(['entityFields:daysOff.end_date', 'entityFields:default.end_date'], '') || undefined,
        approved: t(['entityFields:daysOff.approved', 'entityFields:default.approved'], '') || undefined,
        note: t(['entityFields:daysOff.note', 'entityFields:default.note'], '') || undefined,
        user: t(['entityFields:daysOff.user', 'entityFields:default.user'], '') || undefined,
        createdAt: t(['entityFields:daysOff.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:daysOff.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:daysOff.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;