const schema = [
        ['name'],
        ['subsidyPercentage'],
        ['hasHours'],
        ['hasInvoices'],
        ['order'],
        ['projects'],
        ['parentCategory'],
        ['users'],
];

export default schema;
