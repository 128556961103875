import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import RegulationBaseOrderFormSchema from '@/_base/regulation/RegulationBaseOrderFormSchema';
import RegulationBaseModel from '@/_base/regulation/RegulationBaseModel';
import RegulationBaseSimpleSchema from '@/_base/regulation/RegulationBaseSimpleSchema';
import RegulationBaseTableSchema from '@/_base/regulation/RegulationBaseTableSchema';
import RegulationBaseInsertForm from '@/_base/regulation/components/RegulationBaseInsertForm';
import RegulationBaseUpdateForm from '@/_base/regulation/components/RegulationBaseUpdateForm';
import RegulationBaseTable from '@/_base/regulation/components/RegulationBaseTable';
import RegulationBaseView from '@/_base/regulation/components/RegulationBaseView';

const RegulationEntity: Entity<typeof RegulationBaseModel> = {
  name: 'regulation',

  simpleSchema: RegulationBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(RegulationBaseSimpleSchema),
    orderSchema: [...RegulationBaseOrderFormSchema],
    component: RegulationBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(RegulationBaseSimpleSchema),
    orderSchema: [...RegulationBaseOrderFormSchema],
    component: RegulationBaseUpdateForm,
  },

  table: {
    schema: RegulationBaseTableSchema,
    component: RegulationBaseTable,
  },

  view: {
    component: RegulationBaseView,
  },

  model: RegulationBaseModel,

  routes: {},

  settings: {
    displayField: 'name',
  },
};

export default RegulationEntity;
