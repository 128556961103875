import EntityTable, {
  defaultPage,
  defaultPageSize,
  defaultSortOrder,
  getDefaultSortField,
} from '@boilerplate/components/entity/EntityTable/EntityTable';
import { keyBy, merge, uniq } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import RoleEntity from '@/entities/role';
import TenantEntity from '@/entities/tenant';
import UserEntity from '@/entities/user';
import { UserSortFields, SortDirection, GetUsersQueryVariables } from '@/graphql';

export const useUsersTable = ({ queryOptions }: { queryOptions?: GetUsersQueryVariables } = {}) => {
  const defaultVariableOptions = useMemo<GetUsersQueryVariables>(
    () =>
      merge(
        {
          withUserTenantRoles: true,
          withCategories: true,
          filter: {}, // empty filter is required to correctly reset filters when refetching
          sorting: {
            direction: defaultSortOrder === 'desc' ? SortDirection.Desc : SortDirection.Asc,
            field: getDefaultSortField(UserEntity.table.schema) as UserSortFields,
          },
          paging: {
            page: defaultPage,
            pageSize: defaultPageSize,
          },
        },
        queryOptions
      ),
    [queryOptions]
  );

  const {
    data: userData,
    items = [],
    loading,
    refetch: originalRefetch,
    variables,
  } = UserEntity.model.useGetAll({
    fetchPolicy: 'cache-and-network',
    variables: defaultVariableOptions,
  });
  const { items: roles = [], loading: rolesLoading } = RoleEntity.model.useGetAll({
    fetchPolicy: 'cache-and-network',
  });

  const { items: tenants = [], loading: tenantsLoading } = TenantEntity.model.useGetAll({
    fetchPolicy: 'cache-and-network',
  });

  const [remove] = UserEntity.model.useDelete();

  const rolesById = useMemo(() => keyBy(roles, 'id'), [roles]);
  const tenantsById = useMemo(() => keyBy(tenants, 'id'), [tenants]);
  const useGetAllTenants = TenantEntity.model.useGetAll;

  const refetch = useCallback(
    (variableOptions?: Partial<GetUsersQueryVariables>) => {
      return originalRefetch(merge({}, defaultVariableOptions, variableOptions));
    },
    [defaultVariableOptions, originalRefetch]
  );

  const allActions = useMemo(
    () => ({ loading: loading || rolesLoading || tenantsLoading, refetch, remove, useGetAllTenants }),
    [loading, refetch, remove, rolesLoading, tenantsLoading, useGetAllTenants]
  );
  const data = useMemo(
    () =>
      userData
        ? {
            totalCount: userData.users.totalCount,
            rows: items.map((user) => ({
              ...user,
              roles: user.userTenantRoles?.items?.map((entry) => rolesById[entry.roleId]).filter(Boolean) ?? [],
              tenants: uniq(user.userTenantRoles?.items?.map((entry) => tenantsById[entry.tenantId]?.name).filter(Boolean)).join(', ') ?? [],
            })),
          }
        : { totalCount: 0, rows: [] },
    [userData, items, rolesById, tenantsById]
  );

  return {
    data,
    actions: allActions,
    refetch,
    variables,
    defaultVariableOptions,
    mapQueryBuilder: {
      tenants: (value) => ({
        ['userTenantRoles']: {
          tenantId: { eq: value },
        },
      }),
      projects: (value) => ({
        ['userTenantRoles']: {
          projectId: { eq: value },
        },
      }),
    },
  };
};

export default function UsersTable({ noWrapper }: { noWrapper?: boolean }) {
  const props = useUsersTable();

  return <EntityTable {...props} Entity={UserEntity} noWrapper={noWrapper} />;
}
