import { isDate, format as formatDate } from 'date-fns';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import store from '@/lib/locales';
import { enUS, nl } from 'date-fns/locale';

const locales = { 'en-US': enUS, 'nl-NL': nl };

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    lng: 'nl-NL',
    fallbackLng: 'en-US',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (isDate(value) && format) {
          const locale = locales[lng as keyof typeof locales];

          return formatDate(value, format, { locale });
        }

        return value;
      },
    },
    resources: store,
  });

const t = i18n.t.bind(i18n);

export { t };

export default i18n;
