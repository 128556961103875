import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetContractDocument,
  GetContractQuery,
  GetContractQueryVariables,
  GetContractsDocument,
  GetContractsQuery,
  GetContractsQueryVariables,
  useGetContractQuery,
  useGetContractLazyQuery,
  useGetContractsQuery,
  useGetContractsLazyQuery,

    useGetAllRelatedDataForContractQuery,
    useGetAllRelatedDataForContractLazyQuery,
    GetAllRelatedDataForContractQuery,
    GetAllRelatedDataForContractQueryVariables,

  CreateContractDocument,
  CreateContractMutation,
  CreateContractMutationVariables,
  useCreateContractMutation,

  DeleteContractDocument,
  DeleteContractMutation,
  DeleteContractMutationVariables,
  useDeleteContractMutation,

  UpdateContractDocument,
  UpdateContractMutation,
  UpdateContractMutationVariables,
  useUpdateContractMutation,

    useCreatedContractSubscription,
    useUpdatedContractSubscription,
    useDeletedContractSubscription,
    useRestoredContractSubscription,
} from '@/graphql';

  type ContractCollection = Dictionary<NonNullable<GetContractsQuery['contracts']['items']>[number]>;

const ContractBaseModel = {
  get: (options: Omit<QueryOptions<GetContractQueryVariables, GetContractQuery>, 'query'>) => {
    return apolloClient.query<GetContractQuery, GetContractQueryVariables>({
      ...options,
      query: GetContractDocument,
    })
    .then(({ data }) => data.contract);
  },

  useGet: useGetContractQuery,

  getAll: (options?: Omit<QueryOptions<GetContractsQueryVariables, GetContractsQuery>, 'query'>) => {
    return apolloClient
      .query<GetContractsQuery, GetContractsQueryVariables>({
        ...options,
        query: GetContractsDocument
      })
      .then(({ data }) => data.contracts.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) => {
    const hookResult = useGetContractsQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.contracts?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForContractQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForContractQuery, GetAllRelatedDataForContractQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForContractQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetContractLazyQuery,

  useGetAllLazy: useGetContractsLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForContractLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateContractMutation, CreateContractMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateContractMutation, CreateContractMutationVariables>({
      ...options,
      mutation: CreateContractDocument,
    });
  },

  useCreate: useCreateContractMutation,

  update: (options: Omit<MutationOptions<UpdateContractMutation, UpdateContractMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateContractMutation, UpdateContractMutationVariables>({
      ...options,
      mutation: UpdateContractDocument,
    });
  },

  useUpdate: useUpdateContractMutation,

  delete: (options: Omit<MutationOptions<DeleteContractMutation, DeleteContractMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteContractMutation, DeleteContractMutationVariables>({
      ...options,
      mutation: DeleteContractDocument,
    });
  },

  useDelete: useDeleteContractMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) => {
      const [collection, setCollection] = useState<ContractCollection>({});

      const { items, loading, error, refetch } = ContractBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedContractSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdContract?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdContract.id]: data.createdContract,
            }));
          }
        },
      });

      useUpdatedContractSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedContract?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedContract.id]: data.updatedContract,
            }));
          }
        },
      });

      useDeletedContractSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedContract?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedContract.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredContractSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredContract?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredContract.id]: data.restoredContract,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default ContractBaseModel;
