import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import ProjectDateBaseOrderFormSchema from '@/_base/projectDate/ProjectDateBaseOrderFormSchema';
import ProjectDateBaseModel from '@/_base/projectDate/ProjectDateBaseModel';
import ProjectDateBaseSimpleSchema from '@/_base/projectDate/ProjectDateBaseSimpleSchema';
import ProjectDateBaseTableSchema from '@/_base/projectDate/ProjectDateBaseTableSchema';
import ProjectDateBaseInsertForm from '@/_base/projectDate/components/ProjectDateBaseInsertForm';
import ProjectDateBaseUpdateForm from '@/_base/projectDate/components/ProjectDateBaseUpdateForm';
import ProjectDateBaseTable from '@/_base/projectDate/components/ProjectDateBaseTable';
import ProjectDateBaseView from '@/_base/projectDate/components/ProjectDateBaseView';

const ProjectDateEntity: Entity<typeof ProjectDateBaseModel> = {
  name: 'project_date',

  simpleSchema: ProjectDateBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(ProjectDateBaseSimpleSchema),
    orderSchema: [...ProjectDateBaseOrderFormSchema],
    component: ProjectDateBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(ProjectDateBaseSimpleSchema),
    orderSchema: [...ProjectDateBaseOrderFormSchema],
    component: ProjectDateBaseUpdateForm,
  },

  table: {
    schema: ProjectDateBaseTableSchema,
    component: ProjectDateBaseTable,
  },

  view: {
    component: ProjectDateBaseView,
  },

  model: ProjectDateBaseModel,

  routes: {},

  settings: {
    displayField: 'name',
  },
};

export default ProjectDateEntity;
