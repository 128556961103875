import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetProductivityDocument,
  GetProductivityQuery,
  GetProductivityQueryVariables,
  GetProductivitiesDocument,
  GetProductivitiesQuery,
  GetProductivitiesQueryVariables,
  useGetProductivityQuery,
  useGetProductivityLazyQuery,
  useGetProductivitiesQuery,
  useGetProductivitiesLazyQuery,

    useGetAllRelatedDataForProductivityQuery,
    useGetAllRelatedDataForProductivityLazyQuery,
    GetAllRelatedDataForProductivityQuery,
    GetAllRelatedDataForProductivityQueryVariables,

  CreateProductivityDocument,
  CreateProductivityMutation,
  CreateProductivityMutationVariables,
  useCreateProductivityMutation,

  DeleteProductivityDocument,
  DeleteProductivityMutation,
  DeleteProductivityMutationVariables,
  useDeleteProductivityMutation,

  UpdateProductivityDocument,
  UpdateProductivityMutation,
  UpdateProductivityMutationVariables,
  useUpdateProductivityMutation,

    useCreatedProductivitySubscription,
    useUpdatedProductivitySubscription,
    useDeletedProductivitySubscription,
    useRestoredProductivitySubscription,
} from '@/graphql';

  type ProductivityCollection = Dictionary<NonNullable<GetProductivitiesQuery['productivities']['items']>[number]>;

const ProductivityBaseModel = {
  get: (options: Omit<QueryOptions<GetProductivityQueryVariables, GetProductivityQuery>, 'query'>) => {
    return apolloClient.query<GetProductivityQuery, GetProductivityQueryVariables>({
      ...options,
      query: GetProductivityDocument,
    })
    .then(({ data }) => data.productivity);
  },

  useGet: useGetProductivityQuery,

  getAll: (options?: Omit<QueryOptions<GetProductivitiesQueryVariables, GetProductivitiesQuery>, 'query'>) => {
    return apolloClient
      .query<GetProductivitiesQuery, GetProductivitiesQueryVariables>({
        ...options,
        query: GetProductivitiesDocument
      })
      .then(({ data }) => data.productivities.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetProductivitiesQuery, GetProductivitiesQueryVariables>) => {
    const hookResult = useGetProductivitiesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.productivities?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForProductivityQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForProductivityQuery, GetAllRelatedDataForProductivityQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForProductivityQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetProductivityLazyQuery,

  useGetAllLazy: useGetProductivitiesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForProductivityLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateProductivityMutation, CreateProductivityMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateProductivityMutation, CreateProductivityMutationVariables>({
      ...options,
      mutation: CreateProductivityDocument,
    });
  },

  useCreate: useCreateProductivityMutation,

  update: (options: Omit<MutationOptions<UpdateProductivityMutation, UpdateProductivityMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateProductivityMutation, UpdateProductivityMutationVariables>({
      ...options,
      mutation: UpdateProductivityDocument,
    });
  },

  useUpdate: useUpdateProductivityMutation,

  delete: (options: Omit<MutationOptions<DeleteProductivityMutation, DeleteProductivityMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteProductivityMutation, DeleteProductivityMutationVariables>({
      ...options,
      mutation: DeleteProductivityDocument,
    });
  },

  useDelete: useDeleteProductivityMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetProductivitiesQuery, GetProductivitiesQueryVariables>) => {
      const [collection, setCollection] = useState<ProductivityCollection>({});

      const { items, loading, error, refetch } = ProductivityBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedProductivitySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdProductivity?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdProductivity.id]: data.createdProductivity,
            }));
          }
        },
      });

      useUpdatedProductivitySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedProductivity?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedProductivity.id]: data.updatedProductivity,
            }));
          }
        },
      });

      useDeletedProductivitySubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedProductivity?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedProductivity.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredProductivitySubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredProductivity?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredProductivity.id]: data.restoredProductivity,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default ProductivityBaseModel;
