import { ApolloError } from '@apollo/client';
import PageError from '@boilerplate/components/PageError';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { BaseFormUpdateProps } from '@boilerplate/types/entity';
import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import { CircularProgress } from '@mui/material';
import { sentenceCase } from 'change-case';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import UserEntity from '@/entities/user';
import Notistack from '@/lib/notistack';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

import useRelationsOptions from './useRelationsOptions';

const ignoreFields = ['role', 'tenant', 'project'];

function UserUpdateForm({ formOnly = false, onClose, ...props }: BaseFormUpdateProps<typeof UserEntity.model>) {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUser = useAuthenticatedUserStore();
  const hiddenFields = useMemo(() => {
    if (currentUser.isSuperAdmin) {
      return ['invite'];
    }

    return ['note', 'invite'];
  }, [currentUser.isSuperAdmin]);

  const Component = formOnly ? EntityForm : EntityFormPage;

  const Entity = useMemo<typeof UserEntity>(
    () => ({
      ...UserEntity,
      update: {
        ...UserEntity.update,
        simpleSchema: UserEntity.update.simpleSchema.omit(...ignoreFields),
      },
      simpleSchema: UserEntity.simpleSchema.omit(...ignoreFields) as SimpleSchema,
    }),
    []
  );

  const { loading, error, data } = UserEntity.model.useGet({
    variables: {
      id: id!,
    },
  });

  const [updateMutation, { loading: updateLoading }] = UserEntity.model.useUpdate();

  const relationsOptionsQuery = useRelationsOptions();

  const handleSubmit = (newData) => {
    newData.variables = getGraphqlData(Entity, newData.variables, 'update');

    updateMutation(newData)
      .then((result) => {
        Notistack.toast(t('crud:updatedItem', { item: t('entities:user') }), { variant: 'success' });

        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.updateUser);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  if (loading || relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  if (!data?.user) {
    return <PageError title={`${sentenceCase(Entity.name)} update`}>{new Error('Not found')}</PageError>;
  }

  return (
    <Component
      {...props}
      type="update"
      Entity={Entity}
      data={data.user}
      loading={updateLoading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptionsQuery.data}
      hiddenFields={hiddenFields}
    />
  );
}

export default UserUpdateForm;
