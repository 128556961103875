import DefaultColumnFilters from '@entity/EntityTable/ColumnFilters';

import TeamFilter from './TeamFilter';

// We can import additional Components here to expand or overwrite Boilerplate Components
const ColumnFilters = Object.assign(DefaultColumnFilters, {
  TeamFilter,
});

export default ColumnFilters;
