import { roleNameOrder } from '../../../database/seeds/roles/rolesData';

export const checkPrivilegeLevelsBetweenRoles = (baseRoleNames: string[]) => {
  const getMinRoleIndex = (roleNames: string[]) =>
    Math.min(...roleNames.map((roleName) => roleNameOrder.indexOf(roleName)));

  const minBaseRoleIndex = getMinRoleIndex(baseRoleNames);

  return {
    // Remember, the lower the index the higher the privilege
    isMorePrivilegedThan: (compareRoleNames: string[]) =>
      minBaseRoleIndex < getMinRoleIndex(compareRoleNames),
    isLessPrivilegedThan: (compareRoleNames: string[]) =>
      minBaseRoleIndex > getMinRoleIndex(compareRoleNames),
    isEquallyPrivileged: (compareRoleNames: string[]) =>
      minBaseRoleIndex === getMinRoleIndex(compareRoleNames),
  };
};
