import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const PlannedHourBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:plannedHour.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'month',
        label: t(['entityFields:plannedHour.month', 'entityFields:default.month'], 'month') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
      },
      {
          field: 'hours',
        label: t(['entityFields:plannedHour.hours', 'entityFields:default.hours'], 'hours') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
                    sortable: true,
          numeric: true,
      },
      {
          field: 'project',
        label: t(['entityFields:plannedHour.project', 'entityFields:default.project'], 'project') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'user',
        label: t(['entityFields:plannedHour.user', 'entityFields:default.user'], 'user') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:plannedHour.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:plannedHour.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const PlannedHourBaseTableSchema: TableSchema[] = [
  ...PlannedHourBaseTableSchemaWithoutActions,
  actions,
];

export { actions, PlannedHourBaseTableSchemaWithoutActions };
export default PlannedHourBaseTableSchema;
