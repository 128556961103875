import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const CategoryBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:category.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'name',
        label: t(['entityFields:category.name', 'entityFields:default.name'], 'name') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'subsidyPercentage',
        label: t(['entityFields:category.subsidy_percentage', 'entityFields:default.subsidy_percentage'], 'subsidyPercentage') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['NumberFilter'],
                    sortable: true,
          numeric: true,
        optional: true,
      },
      {
          field: 'hasHours',
        label: t(['entityFields:category.has_hours', 'entityFields:default.has_hours'], 'hasHours') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
      },
      {
          field: 'hasInvoices',
        label: t(['entityFields:category.has_invoices', 'entityFields:default.has_invoices'], 'hasInvoices') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
      },
      {
          field: 'invoices',
        label: t(['entityFields:category.invoice', 'entityFields:default.invoice'], 'invoice') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'parentCategory',
        label: t(['entityFields:category.parent_category', 'entityFields:default.parent_category'], 'parentCategory') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'subCategories',
        label: t(['entityFields:category.sub_categories', 'entityFields:default.sub_categories'], 'subCategories') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'users',
        label: t(['entityFields:category.user', 'entityFields:default.user'], 'user') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:category.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:category.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const CategoryBaseTableSchema: TableSchema[] = [
  ...CategoryBaseTableSchemaWithoutActions,
  actions,
];

export { actions, CategoryBaseTableSchemaWithoutActions };
export default CategoryBaseTableSchema;
