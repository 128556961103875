import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities/graphql/getFromAST';
import { createClient, ClientOptions, ConnectionInitMessage } from 'graphql-ws';
import React from 'react';

import UpdateAvailable from '@/components/shared/UpdateAvailable';
import config from '@/config';
import introspectionQueryResultData from '@/graphql/fragments';
import { catchGraphQlError } from '@/lib/catchError';
import Notistack from '@/lib/notistack';
import { getTenant, logout } from '@/stores/UserStore';

/* Local */

let lastAppVersionNotificationDate: Date;

const isServer = config.app.server === true;
const subscriptionsEnabled = config.app.websocketSubscriptions === true;

const wsClientOptions = {
  url: `${config.app.backendUrl}/graphql`.replace(/^http(s)?/, 'ws$1'),
  shouldRetry: () => true,
  retryAttempts: 12, // Retry the connection 12 times before timing out
  keepAlive: 120000, // ping the server every 12 seconds
  connectionParams() {
    const params: { tenantId?: string } = {};

    const tenantId = getTenant()?.id;

    if (tenantId) {
      params.tenantId = tenantId;
    }

    if (!Object.keys(params).length) {
      return undefined;
    }

    return params;
  },
} as ClientOptions<ConnectionInitMessage['payload']>;

export const wsClient = createClient(wsClientOptions);

const isUpdateAvailable = (headers: any): boolean => {
  const backendVersion = headers.get('App-Version');

  if (!backendVersion) {
    return false;
  }

  const frontendVersion = config.app.version;

  const updateAvailable = (() => {
    if (Number.isNaN(Number(backendVersion)) || Number.isNaN(Number(frontendVersion))) {
      return frontendVersion !== backendVersion;
    }

    return frontendVersion < backendVersion;
  })();

  if (updateAvailable) {
    // eslint-disable-next-line no-console
    console.log('Update available', { backendVersion, frontendVersion });
  }

  return updateAvailable;
};

export function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  // Create the cache first, which we'll share across Apollo tooling.
  // This is an in-memory cache. Since we'll be calling `createApolloClient` on
  // universally, the cache will survive until the HTTP request is
  // responded to (on the server) or for the whole of the user's visit (in
  // the browser)
  // const cache = new InMemoryCache();
  const cache = new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    // This gets rid of updateQuery with fetchMore which will deprecated in next version (possibly)
    // typePolicies: {
    //   Query: {
    //     fields: {
    //       posts: relayStylePagination()
    //     }
    //   }
    // }
  });

  const authLink = setContext((_, prevContext) => {
    const headers: { [header: string]: string } = {
      ...prevContext.headers,
    };

    const tenantId = getTenant()?.id;

    if (tenantId) {
      headers['flystart-tenant'] = tenantId;
    }

    // return the headers to the context so httpLink can read them
    return { headers };
  });

  // Create a HTTP client (both server/client). It takes the GraphQL
  // server from the `BACKEND` environment variable, which by default is
  // set to an external playground at https://graphqlhub.com/graphql
  const httpLink = new HttpLink({
    credentials: 'include',
    uri: `${config.app.backendUrl}/graphql`,
    headers: {
      'x-csrf-protection': '1',
    },
  });

  const appVersionLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;

      if (!isServer && headers) {
        if (isUpdateAvailable(headers)) {
          if (
            !lastAppVersionNotificationDate ||
            new Date(
              lastAppVersionNotificationDate.getTime() + 1000 * 60 * 60 * 30 // 30 minutes
            ) < new Date()
          ) {
            lastAppVersionNotificationDate = new Date();

            const key = Notistack.enqueueSnackbar(
              <UpdateAvailable
                onClick={() => {
                  Notistack.closeSnackbar(key);

                  window.location.reload();
                }}
              />,
              {
                variant: 'info',
                autoHideDuration: null,
              }
            );
          }
        }
      }

      return response;
    });
  });

  // If we're in the browser, we'd have received initial state from the
  // server. Restore it, so the client app can continue with the same data.
  if (!isServer) {
    cache.restore((window as any).__APOLLO__);
  }

  // Return a new Apollo Client back, with the cache we've just created,
  // and an array of 'links' (Apollo parlance for GraphQL middleware)
  // to tell Apollo how to handle GraphQL requests
  return new ApolloClient({
    connectToDevTools: !config.isProduction,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
    link: ApolloLink.from([
      // General error handler, to log errors back to the console.
      // Replace this in production with whatever makes sense in your
      // environment.
      onError(({ graphQLErrors, networkError, ...rest }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach((graphQLError) => {
            const { message, path, locations, extensions } = graphQLError;

            catchGraphQlError(graphQLError);

            console.error(`[GraphQL error]: Message: ${message}, Path: ${path}, Location:`, locations);

            if (extensions?.response?.statusCode === 401) {
              logout();
            }
          });
        }

        if (networkError) {
          console.error(`[Network error]: ${JSON.stringify(networkError)}`, rest);
          new GraphQLWsLink(createClient(wsClientOptions));
        }

        if (rest) {
          console.log('Unknown error, reconnecting');
          new GraphQLWsLink(wsClient);
        }
      }),

      // Split on HTTP and WebSockets
      !isServer && subscriptionsEnabled
        ? split(
            ({ query }) => {
              const definition = getMainDefinition(query);

              return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            // Use WebSockets for subscriptions
            new GraphQLWsLink(wsClient),
            // ... fall-back to HTTP for everything else
            authLink.concat(appVersionLink).concat(httpLink)
          )
        : authLink.concat(appVersionLink).concat(httpLink), // <-- just use HTTP on the server
    ]),
    // On the server, enable SSR mode
    ssrMode: isServer,
  });
}
