import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetProjectDateReminderDocument,
  GetProjectDateReminderQuery,
  GetProjectDateReminderQueryVariables,
  GetProjectDateRemindersDocument,
  GetProjectDateRemindersQuery,
  GetProjectDateRemindersQueryVariables,
  useGetProjectDateReminderQuery,
  useGetProjectDateReminderLazyQuery,
  useGetProjectDateRemindersQuery,
  useGetProjectDateRemindersLazyQuery,

    useGetAllRelatedDataForProjectDateReminderQuery,
    useGetAllRelatedDataForProjectDateReminderLazyQuery,
    GetAllRelatedDataForProjectDateReminderQuery,
    GetAllRelatedDataForProjectDateReminderQueryVariables,

  CreateProjectDateReminderDocument,
  CreateProjectDateReminderMutation,
  CreateProjectDateReminderMutationVariables,
  useCreateProjectDateReminderMutation,

  DeleteProjectDateReminderDocument,
  DeleteProjectDateReminderMutation,
  DeleteProjectDateReminderMutationVariables,
  useDeleteProjectDateReminderMutation,

  UpdateProjectDateReminderDocument,
  UpdateProjectDateReminderMutation,
  UpdateProjectDateReminderMutationVariables,
  useUpdateProjectDateReminderMutation,

    useCreatedProjectDateReminderSubscription,
    useUpdatedProjectDateReminderSubscription,
    useDeletedProjectDateReminderSubscription,
    useRestoredProjectDateReminderSubscription,
} from '@/graphql';

  type ProjectDateReminderCollection = Dictionary<NonNullable<GetProjectDateRemindersQuery['projectDateReminders']['items']>[number]>;

const ProjectDateReminderBaseModel = {
  get: (options: Omit<QueryOptions<GetProjectDateReminderQueryVariables, GetProjectDateReminderQuery>, 'query'>) => {
    return apolloClient.query<GetProjectDateReminderQuery, GetProjectDateReminderQueryVariables>({
      ...options,
      query: GetProjectDateReminderDocument,
    })
    .then(({ data }) => data.projectDateReminder);
  },

  useGet: useGetProjectDateReminderQuery,

  getAll: (options?: Omit<QueryOptions<GetProjectDateRemindersQueryVariables, GetProjectDateRemindersQuery>, 'query'>) => {
    return apolloClient
      .query<GetProjectDateRemindersQuery, GetProjectDateRemindersQueryVariables>({
        ...options,
        query: GetProjectDateRemindersDocument
      })
      .then(({ data }) => data.projectDateReminders.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetProjectDateRemindersQuery, GetProjectDateRemindersQueryVariables>) => {
    const hookResult = useGetProjectDateRemindersQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.projectDateReminders?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForProjectDateReminderQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForProjectDateReminderQuery, GetAllRelatedDataForProjectDateReminderQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForProjectDateReminderQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetProjectDateReminderLazyQuery,

  useGetAllLazy: useGetProjectDateRemindersLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForProjectDateReminderLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateProjectDateReminderMutation, CreateProjectDateReminderMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateProjectDateReminderMutation, CreateProjectDateReminderMutationVariables>({
      ...options,
      mutation: CreateProjectDateReminderDocument,
    });
  },

  useCreate: useCreateProjectDateReminderMutation,

  update: (options: Omit<MutationOptions<UpdateProjectDateReminderMutation, UpdateProjectDateReminderMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateProjectDateReminderMutation, UpdateProjectDateReminderMutationVariables>({
      ...options,
      mutation: UpdateProjectDateReminderDocument,
    });
  },

  useUpdate: useUpdateProjectDateReminderMutation,

  delete: (options: Omit<MutationOptions<DeleteProjectDateReminderMutation, DeleteProjectDateReminderMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteProjectDateReminderMutation, DeleteProjectDateReminderMutationVariables>({
      ...options,
      mutation: DeleteProjectDateReminderDocument,
    });
  },

  useDelete: useDeleteProjectDateReminderMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetProjectDateRemindersQuery, GetProjectDateRemindersQueryVariables>) => {
      const [collection, setCollection] = useState<ProjectDateReminderCollection>({});

      const { items, loading, error, refetch } = ProjectDateReminderBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedProjectDateReminderSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdProjectDateReminder?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdProjectDateReminder.id]: data.createdProjectDateReminder,
            }));
          }
        },
      });

      useUpdatedProjectDateReminderSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedProjectDateReminder?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedProjectDateReminder.id]: data.updatedProjectDateReminder,
            }));
          }
        },
      });

      useDeletedProjectDateReminderSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedProjectDateReminder?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedProjectDateReminder.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredProjectDateReminderSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredProjectDateReminder?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredProjectDateReminder.id]: data.restoredProjectDateReminder,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default ProjectDateReminderBaseModel;
