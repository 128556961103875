import Sanitized from '@boilerplate/components/Sanitized';
import React, { ReactElement, useCallback, useMemo } from 'react';

import { useGetContentsQuery } from '@/graphql';

import createStore from './store/createStore';

type ContentsType = {
  [machineName: string]: {
    [language: string]: string;
  };
};

const [ContentStore, useContentStore] = createStore<{
  contents?: ContentsType;
  currentLanguage: string;
}>('content', {
  currentLanguage: 'nl',
});

function useGetContents() {
  const { contents } = useContentStore();
  const { data } = useGetContentsQuery({
    skip: !!contents,
    variables: {
      withContentFills: true,
    },
  });

  if (!contents && data) {
    const newContents: ContentsType = Object.fromEntries<ContentsType[string]>(
      data.contents.items?.map((contentItem) => {
        const contentPerLanguage = Object.fromEntries<ContentsType[string][string]>(
          contentItem.contentFills?.items?.map((contentFill) => [contentFill.language, contentFill.fill]) ?? []
        );

        return [contentItem.name, contentPerLanguage];
      }) ?? []
    );

    ContentStore.set('contents', newContents);
  }

  return contents;
}

type ReplacementType = { [key: string]: string | number };

function replace(content: string, replacementKey: string, replacementValue: string | number) {
  const search = `{{{${replacementKey.toUpperCase()}}}}`;

  return content.replaceAll(search, replacementValue.toString());
}

function replaceAll(content: string, replacements?: ReplacementType) {
  let replacedContent = ` ${content}`.slice(1); // forced copy

  if (!replacements) {
    return replacedContent;
  }

  for (const [key, value] of Object.entries(replacements)) {
    replacedContent = replace(replacedContent, key, value);
  }

  return replacedContent;
}

export function useContent(replacements?: ReplacementType) {
  const { currentLanguage } = useContentStore();
  const contents = useGetContents();

  return useCallback(
    (machineName: string, component?: string | ReactElement, props?: Record<string, any>) => (
      <Sanitized {...props} component={component}>
        {contents?.[machineName]?.[currentLanguage] ? replaceAll(contents?.[machineName]?.[currentLanguage], replacements) : machineName}
      </Sanitized>
    ),
    [currentLanguage, contents, replacements]
  );
}

export function Content<T = Record<string, any>>({ component, children, ...props }: { children: string; component?: any } & T) {
  const content = useContent();

  return content(children, component, props);
}

/**
 * Get all contents by a group name, and order them.
 * Groups are determined by "." characters in the machine name.
 *
 * For example: useContentGroup('faq.internetPrivacy'), given the contents:
 *
 * - faq.internetPrivacy.10.question = 'q10'
 * - faq.internetPrivacy.10.answer   = 'a10'
 * - faq.internetPrivacy.20.question = 'q20'
 * - faq.internetPrivacy.20.answer   = 'a20'
 * - faq.internetPrivacy.30.question = 'q30'
 * - faq.internetPrivacy.30.answer   = 'a30'
 *
 * will return the following array (always correctly ordered on the index).
 * result = [
 *    { index: '10', question: 'q10', answer: 'a10' },
 *    { index: '20', question: 'q20', answer: 'a20' },
 *    { index: '30', question: 'q30', answer: 'a30' },
 * ];
 */
export function useContentGroup(groupName: string) {
  const contents = useGetContents();
  const content = useContent();

  return useMemo(() => {
    if (!contents) {
      return [];
    }

    const allMachineNames = Object.keys(contents);
    const foundMachineNames = allMachineNames
      .filter((machineName) => machineName.startsWith(`${groupName}.`))
      .map((machineName) => machineName.substring(groupName.length + 1))
      .filter((machineName) => {
        const machineNameParts = machineName.split('.');

        return !Number.isNaN(parseInt(machineNameParts[0], 10));
      });

    const result: {
      [key: string]: string | JSX.Element | JSX.Element[];
      index: string;
    }[] = [];

    foundMachineNames.forEach((machineName) => {
      const machineNameParts = machineName.split('.');
      const intPart = parseInt(machineNameParts[0], 10);

      if (!result[intPart]) {
        result[intPart] = {
          index: machineNameParts[0],
        };
      }

      result[intPart][machineNameParts[1]] = content(`${groupName}.${machineName}`);
    });

    return result.filter((item) => !!item);
  }, [groupName, contents, content]);
}
