import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormUpdateProps } from '@boilerplate/types/entity';
import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import Notistack from '@/lib/notistack';
import AvailabilityEntity from '@/entities/availability';
import { catchGraphQlError } from '@/lib/catchError';

  import UserEntity from '@/entities/user';

function AvailabilityBaseUpdateForm({ formOnly = false, onClose, Entity = AvailabilityEntity, id: propId, ...props }: BaseFormUpdateProps<typeof AvailabilityEntity.model>) {
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const id = propId || paramId;

  const Component = formOnly ? EntityForm : EntityFormPage;

  const { loading, data } = AvailabilityEntity.model.useGet({
    variables: {
      id,
            withUser: true,
    },
  });

  const [updateMutation, { loading: updateLoading }] = AvailabilityEntity.model.useUpdate();

  const relationsOptionsQuery = AvailabilityEntity.model.useRelationsOptions({
    variables: {
          withUsers: true,
    }
  });

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(relationsOptionsQuery, [
              UserEntity,
      ]),
    [relationsOptionsQuery]
  );

  const handleSubmit = (newData) => {
    newData.variables = getGraphqlData(Entity, newData.variables, 'update');

    updateMutation(newData)
      .then((result) => {
        Notistack.toast(t('crud:updatedItem', { item: t('entities:availability') }), { variant: 'success' });

        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.updateAvailability);
      })
      .catch(catchGraphQlError);
  };

  if (loading || relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="update"
      Entity={Entity}
      loading={updateLoading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptions}
      data={data.availability}
    />
  );
}

export default AvailabilityBaseUpdateForm;
