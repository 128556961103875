import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormUpdateProps } from '@boilerplate/types/entity';
import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import Notistack from '@/lib/notistack';
import RoleEntity from '@/entities/role';
import { catchGraphQlError } from '@/lib/catchError';

  import PermissionEntity from '@/entities/permission';
  import UserTenantRoleEntity from '@/entities/userTenantRole';
  import ProjectDateReminderEntity from '@/entities/projectDateReminder';

function RoleBaseUpdateForm({ formOnly = false, onClose, Entity = RoleEntity, id: propId, ...props }: BaseFormUpdateProps<typeof RoleEntity.model>) {
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const id = propId || paramId;

  const Component = formOnly ? EntityForm : EntityFormPage;

  const { loading, data } = RoleEntity.model.useGet({
    variables: {
      id,
            withPermissions: true,
            withProjectDateReminders: true,
    },
  });

  const [updateMutation, { loading: updateLoading }] = RoleEntity.model.useUpdate();

  const relationsOptionsQuery = RoleEntity.model.useRelationsOptions({
    variables: {
          withPermissions: true,
          withProjectDateReminders: true,
    }
  });

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(relationsOptionsQuery, [
              PermissionEntity,
              ProjectDateReminderEntity,
      ]),
    [relationsOptionsQuery]
  );

  const handleSubmit = (newData) => {
    newData.variables = getGraphqlData(Entity, newData.variables, 'update');

    updateMutation(newData)
      .then((result) => {
        Notistack.toast(t('crud:updatedItem', { item: t('entities:role') }), { variant: 'success' });

        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.updateRole);
      })
      .catch(catchGraphQlError);
  };

  if (loading || relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="update"
      Entity={Entity}
      loading={updateLoading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptions}
      data={data.role}
    />
  );
}

export default RoleBaseUpdateForm;
