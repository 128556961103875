import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import ProductivityBaseOrderFormSchema from '@/_base/productivity/ProductivityBaseOrderFormSchema';
import ProductivityBaseModel from '@/_base/productivity/ProductivityBaseModel';
import ProductivityBaseSimpleSchema from '@/_base/productivity/ProductivityBaseSimpleSchema';
import ProductivityBaseTableSchema from '@/_base/productivity/ProductivityBaseTableSchema';
import ProductivityBaseInsertForm from '@/_base/productivity/components/ProductivityBaseInsertForm';
import ProductivityBaseUpdateForm from '@/_base/productivity/components/ProductivityBaseUpdateForm';
import ProductivityBaseTable from '@/_base/productivity/components/ProductivityBaseTable';
import ProductivityBaseView from '@/_base/productivity/components/ProductivityBaseView';

const ProductivityEntity: Entity<typeof ProductivityBaseModel> = {
  name: 'productivity',

  simpleSchema: ProductivityBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(ProductivityBaseSimpleSchema),
    orderSchema: [...ProductivityBaseOrderFormSchema],
    component: ProductivityBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(ProductivityBaseSimpleSchema),
    orderSchema: [...ProductivityBaseOrderFormSchema],
    component: ProductivityBaseUpdateForm,
  },

  table: {
    schema: ProductivityBaseTableSchema,
    component: ProductivityBaseTable,
  },

  view: {
    component: ProductivityBaseView,
  },

  model: ProductivityBaseModel,

  routes: {},

  settings: {
    displayField: 'id',
  },
};

export default ProductivityEntity;
