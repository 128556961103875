import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        dateTime: {
          type: Date,
          form: {
            component: 'Date',
          },
          view: true,
        },
        type: {
          type: String,
          form: {
            component: 'Select',
          },
          view: true,
          allowedValues: [
            'deadline',
            'interim report',
            'reminder',
            'other',
          ],
        },
        doneAt: {
          type: Date,
          optional: true,
          form: {
            component: 'Date',
          },
          view: true,
        },
        projectDateReminders: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'projectDateReminder',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'projectDateReminders.$': {
          type: String,
          optional: true,
        },
        project: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'project',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:projectDate.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:projectDate.name', 'entityFields:default.name'], '') || undefined,
        dateTime: t(['entityFields:projectDate.date_time', 'entityFields:default.date_time'], '') || undefined,
        type: t(['entityFields:projectDate.type', 'entityFields:default.type'], '') || undefined,
        doneAt: t(['entityFields:projectDate.done_at', 'entityFields:default.done_at'], '') || undefined,
        projectDateReminders: t(['entityFields:projectDate.project_date_reminder', 'entityFields:default.project_date_reminder'], '') || undefined,
        project: t(['entityFields:projectDate.project', 'entityFields:default.project'], '') || undefined,
});

export default schema;