const schema = [
        ['name'],
        ['active'],
        ['description'],
        ['branch'],
        ['subsidyPercentage'],
        ['invoiceVatSubsidyEligible'],
        ['reference'],
        ['status'],
        ['hourSubsidyType'],
        ['maxHourlyRate'],
        ['addendum'],
        ['startDate'],
        ['endDate'],
        ['filingDate'],
        ['decisionDate'],
        ['categories'],
        ['tenants'],
        ['regulation'],
        ['responsibleUser'],
        ['controlUser'],
];

export default schema;
