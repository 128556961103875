import DefaultProfilePage from '@boilerplate/components/app/Profile/DefaultProfilePage';
import { Container } from '@mui/material';
import React from 'react';

export default function ProfilePage() {
  return (
    <Container>
      <DefaultProfilePage noAvatar />
    </Container>
  );
}
