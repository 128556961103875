import { QueryHookOptions } from '@apollo/client';
import { map, sortBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ProjectBaseModel from '@/_base/project/ProjectBaseModel';
import ProjectBaseSimpleSchema from '@/_base/project/ProjectBaseSimpleSchema';
import {
  ProjectsWhereAdminWithContractsQuery,
  ProjectsWhereAdminWithContractsQueryVariables,
  useCreateSimpleProjectMutation,
  useGetProjectsWithCategoriesQuery,
  useProjectsWhereAdminQuery,
  useProjectsWhereAdminWithContractsQuery,
} from '@/graphql';

const ProjectModel = {
  ...ProjectBaseModel,
  useGetProjectsWithCategories: () => {
    const hookResult = useGetProjectsWithCategoriesQuery();

    const projects = useMemo(() => {
      if (!hookResult.data?.projectWithCategories) {
        return [];
      }

      return sortBy(
        map(hookResult.data?.projectWithCategories?.items, (project) => ({
          ...project,
          categories: {
            items: sortBy(project.categories.items, ['name']).filter((category) => {
              if (category.subCategories?.items?.length) {
                return false;
              }

              return true;
            }),
          },
        })),
        ['customer.name', 'name']
      );
    }, [hookResult]);

    return {
      ...hookResult,
      projects,
    };
  },
  useGetProjectsWithCategoriesHasHours: () => {
    const hookResult = useGetProjectsWithCategoriesQuery();

    const projects = useMemo(() => {
      if (!hookResult.data?.projectWithCategories) {
        return [];
      }

      const formattedProjects = hookResult.data?.projectWithCategories?.items?.map((project) => ({
        ...project,
        categories: {
          items: sortBy(project.categories.items, ['order']).filter((category) => {
            if (!category.hasHours) {
              return false;
            }

            if (category.subCategories?.items?.length) {
              return false;
            }

            return true;
          }),
        },
      }));

      return sortBy(formattedProjects, ['customer.name', 'name']) as typeof formattedProjects;
    }, [hookResult]);

    return {
      ...hookResult,
      projects,
    };
  },

  useCreateSimpleProject: useCreateSimpleProjectMutation,

  useGetWhereAdmin: (...args: Parameters<typeof useProjectsWhereAdminQuery>) => {
    const hookResult = useProjectsWhereAdminQuery(...args);

    const items = useMemo(() => {
      return hookResult.data?.projectsWhereAdmin?.items ?? [];
    }, [hookResult]);

    return {
      items,
      ...hookResult,
    };
  },

  useGetWhereAdminWithContracts: (
    options?: QueryHookOptions<ProjectsWhereAdminWithContractsQuery, ProjectsWhereAdminWithContractsQueryVariables>
  ) => {
    const hookResult = useProjectsWhereAdminWithContractsQuery(options);

    const items = useMemo(() => hookResult.data?.projectsWhereAdmin?.items ?? [], [hookResult.data?.projectsWhereAdmin?.items]);

    return {
      items,
      ...hookResult,
    };
  },

  useStatusOptions: () => {
    const { t } = useTranslation();

    return ProjectBaseSimpleSchema.getAllowedValuesForKey('status').map((status) => ({
      value: status,
      label: t(`entityFields:project.$status_options.${status}`),
    }));
  },
};

export default ProjectModel;
