import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const UserBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:user.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'name',
        label: t(['entityFields:user.name', 'entityFields:default.name'], 'name') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'email',
        label: t(['entityFields:user.email', 'entityFields:default.email'], 'email') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'emailVerifiedAt',
        label: t(['entityFields:user.email_verified_at', 'entityFields:default.email_verified_at'], 'emailVerifiedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'active',
        label: t(['entityFields:user.active', 'entityFields:default.active'], 'active') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
      },
      {
          field: 'totpVerified',
        label: t(['entityFields:user.totp_verified', 'entityFields:default.totp_verified'], 'totpVerified') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'termsAndConditions',
        label: t(['entityFields:user.terms_and_conditions', 'entityFields:default.terms_and_conditions'], 'termsAndConditions') || undefined,
          cell: CellRenderers['BooleanRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['BooleanFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:user.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'lastName',
        label: t(['entityFields:user.last_name', 'entityFields:default.last_name'], 'lastName') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'function',
        label: t(['entityFields:user.function', 'entityFields:default.function'], 'function') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
        optional: true,
      },
      {
          field: 'phoneNumber',
        label: t(['entityFields:user.phone_number', 'entityFields:default.phone_number'], 'phoneNumber') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
        optional: true,
      },
];

const UserBaseTableSchema: TableSchema[] = [
  ...UserBaseTableSchemaWithoutActions,
  actions,
];

export { actions, UserBaseTableSchemaWithoutActions };
export default UserBaseTableSchema;
