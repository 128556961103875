import { QueryHookOptions } from '@apollo/client';
import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import UserBaseModel from '@/_base/user/UserBaseModel';
import {
  useCreateUserWithUserTenantMutation,
  GetAllRelatedDataForUserQuery,
  GetAllRelatedDataForUserQueryVariables,
  useGetAllRelatedDataForUserQuery,
  useGetFullUserQuery,
} from '@/graphql';

import RoleEntity from '../role';
import TenantEntity from '../tenant';

const UserModel = {
  ...UserBaseModel,
  getFull: useGetFullUserQuery,
  useCreateOrganizationProjectUser: useCreateUserWithUserTenantMutation,
  useRelationsOptions: (baseOptions?: QueryHookOptions<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>) => {
    const hookResult = useGetAllRelatedDataForUserQuery(baseOptions);
    const rolesResult = RoleEntity.model.useGetAll();
    const tenantResult = TenantEntity.model.useGetAll();

    if (!hookResult.data || !rolesResult.data || !tenantResult.data) {
      return { ...hookResult, items: [] };
    }

    return {
      ...hookResult,

      loading: hookResult.loading || rolesResult.loading || tenantResult.loading,
      items: relatedDataToOptions({
        userTenantRoles: {
          items: hookResult.data.userTenantRoles?.items?.map((userTenantRole) => ({
            id: userTenantRole.id,
            displayField: userTenantRole.displayField,
          })),
        },
        roles: { items: rolesResult.data?.roles.items?.map((role) => ({ id: role.id, displayField: role.displayName })) },
        tenants: { items: tenantResult.data?.tenants.items?.map((tenant) => ({ id: tenant.id, displayField: tenant.name })) },
      }),
    };
  },
};

export default UserModel;
