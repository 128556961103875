import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';
import { getI18n } from 'react-i18next';

import ProjectBaseOrderFormSchema from '@/_base/project/ProjectBaseOrderFormSchema';
import ProjectBaseSimpleSchema from '@/_base/project/ProjectBaseSimpleSchema';
import ProjectBaseTableSchema from '@/_base/project/ProjectBaseTableSchema';
import ProjectBaseInsertForm from '@/_base/project/components/ProjectBaseInsertForm';
import ProjectBaseUpdateForm from '@/_base/project/components/ProjectBaseUpdateForm';
import { CellRenderers, ColumnFilters } from '@/components/entity';

import ProjectModel from './ProjectModel';
import ProjectBaseView from '@/_base/project/components/ProjectBaseView';
import ProjectsTable from './ProjectTable';

const { t } = getI18n();

const firstFields = [['tenants'], ['regulation']];
const ProjectOrderFormSchema = [
  ...firstFields,
  ...ProjectBaseOrderFormSchema.map((row) => row.filter((field) => !firstFields.flat().includes(field))).filter((row) => row.length > 0),
];

export const ProjectSimpleSchema = ProjectBaseSimpleSchema.extend({
  tenants: {
    optional: false,
    minCount: 1,
  },
});

const indexOfTenants = ProjectBaseTableSchema.findIndex((item) => item.field === 'tenants');

ProjectBaseTableSchema.splice(indexOfTenants, 1, {
  field: 'tenants',
  label: t(['entityFields:userTenantRole.tenant', 'entityFields:default.tenant'], 'tenant') || undefined,
  cell: CellRenderers.AutoRenderer,
  filter: ColumnFilters.TeamFilter,
  sortable: false,
});

const ProjectEntity: Entity<typeof ProjectModel> = {
  name: 'project',

  simpleSchema: ProjectSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(ProjectSimpleSchema),
    orderSchema: ProjectOrderFormSchema,
    component: ProjectBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(ProjectSimpleSchema),
    orderSchema: ProjectOrderFormSchema,
    component: ProjectBaseUpdateForm,
  },

  table: {
    schema: ProjectBaseTableSchema,
    component: ProjectsTable,
  },

  view: {
    component: ProjectBaseView,
  },

  model: ProjectModel,

  routes: {},

  settings: {
    displayField: 'name',
  },
};

export default ProjectEntity;
