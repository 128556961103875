import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import CategoryBaseOrderFormSchema from '@/_base/category/CategoryBaseOrderFormSchema';
import CategoryBaseModel from '@/_base/category/CategoryBaseModel';
import CategoryBaseSimpleSchema from '@/_base/category/CategoryBaseSimpleSchema';
import CategoryBaseTableSchema from '@/_base/category/CategoryBaseTableSchema';
import CategoryBaseInsertForm from '@/_base/category/components/CategoryBaseInsertForm';
import CategoryBaseUpdateForm from '@/_base/category/components/CategoryBaseUpdateForm';
import CategoryBaseTable from '@/_base/category/components/CategoryBaseTable';
import CategoryBaseView from '@/_base/category/components/CategoryBaseView';

const CategoryEntity: Entity<typeof CategoryBaseModel> = {
  name: 'category',

  simpleSchema: CategoryBaseSimpleSchema.extend({
    projects: {
      optional: false,
      minCount: 1,
    },
  }),

  insert: {
    simpleSchema: removeExcludedFormFields(CategoryBaseSimpleSchema).omit('order', 'users'),
    orderSchema: [...CategoryBaseOrderFormSchema],
    component: CategoryBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(CategoryBaseSimpleSchema).omit('order'),
    orderSchema: [...CategoryBaseOrderFormSchema],
    component: CategoryBaseUpdateForm,
  },

  table: {
    schema: CategoryBaseTableSchema,
    component: CategoryBaseTable,
  },

  view: {
    component: CategoryBaseView,
  },

  model: CategoryBaseModel,

  routes: {},

  settings: {
    displayField: 'name',
  },
};

export default CategoryEntity;
