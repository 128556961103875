import availability from './availability.json';
import budget from './budget.json';
import budgetLine from './budgetLine.json';
import category from './category.json';
import content from './content.json';
import contentFill from './contentFill.json';
import contract from './contract.json';
import customer from './customer.json';
import daysOff from './daysOff.json';
import defaultTranslations from './default.json';
import errorLog from './errorLog.json';
import eventLog from './eventLog.json';
import hoursRequired from './hoursRequired.json';
import invoice from './invoice.json';
import job from './job.json';
import loggedHour from './loggedHour.json';
import mailLog from './mailLog.json';
import mailTemplate from './mailTemplate.json';
import mailTemplateFill from './mailTemplateFill.json';
import permission from './permission.json';
import plannedHour from './plannedHour.json';
import productivity from './productivity.json';
import project from './project.json';
import projectDate from './projectDate.json';
import projectDateReminder from './projectDateReminder.json';
import projectDocument from './projectDocument.json';
import regulation from './regulation.json';
import resetPassword from './resetPassword.json';
import role from './role.json';
import salary from './salary.json';
import tenant from './tenant.json';
import user from './user.json';
import userTenantRole from './userTenantRole.json';

export default {
  availability,
  budget,
  budgetLine,
  category,
  content,
  contentFill,
  contract,
  customer,
  daysOff,
  default: defaultTranslations,
  errorLog,
  eventLog,
  hoursRequired,
  invoice,
  job,
  loggedHour,
  mailLog,
  mailTemplate,
  mailTemplateFill,
  permission,
  plannedHour,
  productivity,
  project,
  projectDate,
  projectDateReminder,
  projectDocument,
  regulation,
  resetPassword,
  role,
  salary,
  tenant,
  user,
  userTenantRole,
};
