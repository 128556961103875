import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetProjectDateDocument,
  GetProjectDateQuery,
  GetProjectDateQueryVariables,
  GetProjectDatesDocument,
  GetProjectDatesQuery,
  GetProjectDatesQueryVariables,
  useGetProjectDateQuery,
  useGetProjectDateLazyQuery,
  useGetProjectDatesQuery,
  useGetProjectDatesLazyQuery,

    useGetAllRelatedDataForProjectDateQuery,
    useGetAllRelatedDataForProjectDateLazyQuery,
    GetAllRelatedDataForProjectDateQuery,
    GetAllRelatedDataForProjectDateQueryVariables,

  CreateProjectDateDocument,
  CreateProjectDateMutation,
  CreateProjectDateMutationVariables,
  useCreateProjectDateMutation,

  DeleteProjectDateDocument,
  DeleteProjectDateMutation,
  DeleteProjectDateMutationVariables,
  useDeleteProjectDateMutation,

  UpdateProjectDateDocument,
  UpdateProjectDateMutation,
  UpdateProjectDateMutationVariables,
  useUpdateProjectDateMutation,

    useCreatedProjectDateSubscription,
    useUpdatedProjectDateSubscription,
    useDeletedProjectDateSubscription,
    useRestoredProjectDateSubscription,
} from '@/graphql';

  type ProjectDateCollection = Dictionary<NonNullable<GetProjectDatesQuery['projectDates']['items']>[number]>;

const ProjectDateBaseModel = {
  get: (options: Omit<QueryOptions<GetProjectDateQueryVariables, GetProjectDateQuery>, 'query'>) => {
    return apolloClient.query<GetProjectDateQuery, GetProjectDateQueryVariables>({
      ...options,
      query: GetProjectDateDocument,
    })
    .then(({ data }) => data.projectDate);
  },

  useGet: useGetProjectDateQuery,

  getAll: (options?: Omit<QueryOptions<GetProjectDatesQueryVariables, GetProjectDatesQuery>, 'query'>) => {
    return apolloClient
      .query<GetProjectDatesQuery, GetProjectDatesQueryVariables>({
        ...options,
        query: GetProjectDatesDocument
      })
      .then(({ data }) => data.projectDates.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetProjectDatesQuery, GetProjectDatesQueryVariables>) => {
    const hookResult = useGetProjectDatesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.projectDates?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForProjectDateQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForProjectDateQuery, GetAllRelatedDataForProjectDateQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForProjectDateQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetProjectDateLazyQuery,

  useGetAllLazy: useGetProjectDatesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForProjectDateLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateProjectDateMutation, CreateProjectDateMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateProjectDateMutation, CreateProjectDateMutationVariables>({
      ...options,
      mutation: CreateProjectDateDocument,
    });
  },

  useCreate: useCreateProjectDateMutation,

  update: (options: Omit<MutationOptions<UpdateProjectDateMutation, UpdateProjectDateMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateProjectDateMutation, UpdateProjectDateMutationVariables>({
      ...options,
      mutation: UpdateProjectDateDocument,
    });
  },

  useUpdate: useUpdateProjectDateMutation,

  delete: (options: Omit<MutationOptions<DeleteProjectDateMutation, DeleteProjectDateMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteProjectDateMutation, DeleteProjectDateMutationVariables>({
      ...options,
      mutation: DeleteProjectDateDocument,
    });
  },

  useDelete: useDeleteProjectDateMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetProjectDatesQuery, GetProjectDatesQueryVariables>) => {
      const [collection, setCollection] = useState<ProjectDateCollection>({});

      const { items, loading, error, refetch } = ProjectDateBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedProjectDateSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdProjectDate?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdProjectDate.id]: data.createdProjectDate,
            }));
          }
        },
      });

      useUpdatedProjectDateSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedProjectDate?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedProjectDate.id]: data.updatedProjectDate,
            }));
          }
        },
      });

      useDeletedProjectDateSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedProjectDate?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedProjectDate.id];

              return newCollection;
            });
          }
        },
      });


      return { collection, loading, error, refetch };
    },
};

export default ProjectDateBaseModel;
