import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const ProjectDateBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:projectDate.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'name',
        label: t(['entityFields:projectDate.name', 'entityFields:default.name'], 'name') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                    sortable: true,
      },
      {
          field: 'dateTime',
        label: t(['entityFields:projectDate.date_time', 'entityFields:default.date_time'], 'dateTime') || undefined,
          cell: CellRenderers['DateRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateFilter'],
            sortable: true,
      },
      {
          field: 'type',
        label: t(['entityFields:projectDate.type', 'entityFields:default.type'], 'type') || undefined,
          cell: CellRenderers['SelectRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['SelectFilter'],
            sortable: true,
        filterOptions: [
          'deadline',
          'interim report',
          'reminder',
          'other',
        ],
      },
      {
          field: 'doneAt',
        label: t(['entityFields:projectDate.done_at', 'entityFields:default.done_at'], 'doneAt') || undefined,
          cell: CellRenderers['DateRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'projectDateReminders',
        label: t(['entityFields:projectDate.project_date_reminder', 'entityFields:default.project_date_reminder'], 'projectDateReminder') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['ArrayFilter'],
                  sortable: false,
        optional: true,
      },
      {
          field: 'project',
        label: t(['entityFields:projectDate.project', 'entityFields:default.project'], 'project') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
];

const ProjectDateBaseTableSchema: TableSchema[] = [
  ...ProjectDateBaseTableSchemaWithoutActions,
  actions,
];

export { actions, ProjectDateBaseTableSchemaWithoutActions };
export default ProjectDateBaseTableSchema;
