import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetPlannedHourDocument,
  GetPlannedHourQuery,
  GetPlannedHourQueryVariables,
  GetPlannedHoursDocument,
  GetPlannedHoursQuery,
  GetPlannedHoursQueryVariables,
  useGetPlannedHourQuery,
  useGetPlannedHourLazyQuery,
  useGetPlannedHoursQuery,
  useGetPlannedHoursLazyQuery,

    useGetAllRelatedDataForPlannedHourQuery,
    useGetAllRelatedDataForPlannedHourLazyQuery,
    GetAllRelatedDataForPlannedHourQuery,
    GetAllRelatedDataForPlannedHourQueryVariables,

  CreatePlannedHourDocument,
  CreatePlannedHourMutation,
  CreatePlannedHourMutationVariables,
  useCreatePlannedHourMutation,

  DeletePlannedHourDocument,
  DeletePlannedHourMutation,
  DeletePlannedHourMutationVariables,
  useDeletePlannedHourMutation,

  UpdatePlannedHourDocument,
  UpdatePlannedHourMutation,
  UpdatePlannedHourMutationVariables,
  useUpdatePlannedHourMutation,

    useCreatedPlannedHourSubscription,
    useUpdatedPlannedHourSubscription,
    useDeletedPlannedHourSubscription,
    useRestoredPlannedHourSubscription,
} from '@/graphql';

  type PlannedHourCollection = Dictionary<NonNullable<GetPlannedHoursQuery['plannedHours']['items']>[number]>;

const PlannedHourBaseModel = {
  get: (options: Omit<QueryOptions<GetPlannedHourQueryVariables, GetPlannedHourQuery>, 'query'>) => {
    return apolloClient.query<GetPlannedHourQuery, GetPlannedHourQueryVariables>({
      ...options,
      query: GetPlannedHourDocument,
    })
    .then(({ data }) => data.plannedHour);
  },

  useGet: useGetPlannedHourQuery,

  getAll: (options?: Omit<QueryOptions<GetPlannedHoursQueryVariables, GetPlannedHoursQuery>, 'query'>) => {
    return apolloClient
      .query<GetPlannedHoursQuery, GetPlannedHoursQueryVariables>({
        ...options,
        query: GetPlannedHoursDocument
      })
      .then(({ data }) => data.plannedHours.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetPlannedHoursQuery, GetPlannedHoursQueryVariables>) => {
    const hookResult = useGetPlannedHoursQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.plannedHours?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForPlannedHourQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForPlannedHourQuery, GetAllRelatedDataForPlannedHourQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForPlannedHourQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetPlannedHourLazyQuery,

  useGetAllLazy: useGetPlannedHoursLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForPlannedHourLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreatePlannedHourMutation, CreatePlannedHourMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreatePlannedHourMutation, CreatePlannedHourMutationVariables>({
      ...options,
      mutation: CreatePlannedHourDocument,
    });
  },

  useCreate: useCreatePlannedHourMutation,

  update: (options: Omit<MutationOptions<UpdatePlannedHourMutation, UpdatePlannedHourMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdatePlannedHourMutation, UpdatePlannedHourMutationVariables>({
      ...options,
      mutation: UpdatePlannedHourDocument,
    });
  },

  useUpdate: useUpdatePlannedHourMutation,

  delete: (options: Omit<MutationOptions<DeletePlannedHourMutation, DeletePlannedHourMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeletePlannedHourMutation, DeletePlannedHourMutationVariables>({
      ...options,
      mutation: DeletePlannedHourDocument,
    });
  },

  useDelete: useDeletePlannedHourMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetPlannedHoursQuery, GetPlannedHoursQueryVariables>) => {
      const [collection, setCollection] = useState<PlannedHourCollection>({});

      const { items, loading, error, refetch } = PlannedHourBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedPlannedHourSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdPlannedHour?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdPlannedHour.id]: data.createdPlannedHour,
            }));
          }
        },
      });

      useUpdatedPlannedHourSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedPlannedHour?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedPlannedHour.id]: data.updatedPlannedHour,
            }));
          }
        },
      });

      useDeletedPlannedHourSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedPlannedHour?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedPlannedHour.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredPlannedHourSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredPlannedHour?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredPlannedHour.id]: data.restoredPlannedHour,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default PlannedHourBaseModel;
