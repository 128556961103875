import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetAvailabilityDocument,
  GetAvailabilityQuery,
  GetAvailabilityQueryVariables,
  GetAvailabilitiesDocument,
  GetAvailabilitiesQuery,
  GetAvailabilitiesQueryVariables,
  useGetAvailabilityQuery,
  useGetAvailabilityLazyQuery,
  useGetAvailabilitiesQuery,
  useGetAvailabilitiesLazyQuery,

    useGetAllRelatedDataForAvailabilityQuery,
    useGetAllRelatedDataForAvailabilityLazyQuery,
    GetAllRelatedDataForAvailabilityQuery,
    GetAllRelatedDataForAvailabilityQueryVariables,

  CreateAvailabilityDocument,
  CreateAvailabilityMutation,
  CreateAvailabilityMutationVariables,
  useCreateAvailabilityMutation,

  DeleteAvailabilityDocument,
  DeleteAvailabilityMutation,
  DeleteAvailabilityMutationVariables,
  useDeleteAvailabilityMutation,

  UpdateAvailabilityDocument,
  UpdateAvailabilityMutation,
  UpdateAvailabilityMutationVariables,
  useUpdateAvailabilityMutation,

    useCreatedAvailabilitySubscription,
    useUpdatedAvailabilitySubscription,
    useDeletedAvailabilitySubscription,
    useRestoredAvailabilitySubscription,
} from '@/graphql';

  type AvailabilityCollection = Dictionary<NonNullable<GetAvailabilitiesQuery['availabilities']['items']>[number]>;

const AvailabilityBaseModel = {
  get: (options: Omit<QueryOptions<GetAvailabilityQueryVariables, GetAvailabilityQuery>, 'query'>) => {
    return apolloClient.query<GetAvailabilityQuery, GetAvailabilityQueryVariables>({
      ...options,
      query: GetAvailabilityDocument,
    })
    .then(({ data }) => data.availability);
  },

  useGet: useGetAvailabilityQuery,

  getAll: (options?: Omit<QueryOptions<GetAvailabilitiesQueryVariables, GetAvailabilitiesQuery>, 'query'>) => {
    return apolloClient
      .query<GetAvailabilitiesQuery, GetAvailabilitiesQueryVariables>({
        ...options,
        query: GetAvailabilitiesDocument
      })
      .then(({ data }) => data.availabilities.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetAvailabilitiesQuery, GetAvailabilitiesQueryVariables>) => {
    const hookResult = useGetAvailabilitiesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.availabilities?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForAvailabilityQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForAvailabilityQuery, GetAllRelatedDataForAvailabilityQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForAvailabilityQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetAvailabilityLazyQuery,

  useGetAllLazy: useGetAvailabilitiesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForAvailabilityLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateAvailabilityMutation, CreateAvailabilityMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateAvailabilityMutation, CreateAvailabilityMutationVariables>({
      ...options,
      mutation: CreateAvailabilityDocument,
    });
  },

  useCreate: useCreateAvailabilityMutation,

  update: (options: Omit<MutationOptions<UpdateAvailabilityMutation, UpdateAvailabilityMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateAvailabilityMutation, UpdateAvailabilityMutationVariables>({
      ...options,
      mutation: UpdateAvailabilityDocument,
    });
  },

  useUpdate: useUpdateAvailabilityMutation,

  delete: (options: Omit<MutationOptions<DeleteAvailabilityMutation, DeleteAvailabilityMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteAvailabilityMutation, DeleteAvailabilityMutationVariables>({
      ...options,
      mutation: DeleteAvailabilityDocument,
    });
  },

  useDelete: useDeleteAvailabilityMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetAvailabilitiesQuery, GetAvailabilitiesQueryVariables>) => {
      const [collection, setCollection] = useState<AvailabilityCollection>({});

      const { items, loading, error, refetch } = AvailabilityBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedAvailabilitySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdAvailability?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdAvailability.id]: data.createdAvailability,
            }));
          }
        },
      });

      useUpdatedAvailabilitySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedAvailability?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedAvailability.id]: data.updatedAvailability,
            }));
          }
        },
      });

      useDeletedAvailabilitySubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedAvailability?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedAvailability.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredAvailabilitySubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredAvailability?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredAvailability.id]: data.restoredAvailability,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default AvailabilityBaseModel;
