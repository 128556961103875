import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        hoursPerWeek: {
          type: Number,
          optional: true,
          form: true,
          view: true,
        },
        costPerHour: {
          type: Number,
          form: true,
          view: true,
        },
        startDate: {
          type: Date,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        endDate: {
          type: Date,
          optional: true,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        hourlyRateInputs: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        customCostPerHour: {
          type: Number,
          optional: true,
          form: true,
          view: true,
        },
        salarySlip: {
          type: String,
          optional: true,
          form: {
            component: 'Upload',
          },
          view: true,
        },
        user: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          form: true,
          view: true,
        },
        tenant: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'tenant',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        project: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'project',
            displayField: 'name',
          },
          optional: true,
          form: true,
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:contract.id', 'entityFields:default.id'], '') || undefined,
        hoursPerWeek: t(['entityFields:contract.hours_per_week', 'entityFields:default.hours_per_week'], '') || undefined,
        costPerHour: t(['entityFields:contract.cost_per_hour', 'entityFields:default.cost_per_hour'], '') || undefined,
        startDate: t(['entityFields:contract.start_date', 'entityFields:default.start_date'], '') || undefined,
        endDate: t(['entityFields:contract.end_date', 'entityFields:default.end_date'], '') || undefined,
        hourlyRateInputs: t(['entityFields:contract.hourly_rate_inputs', 'entityFields:default.hourly_rate_inputs'], '') || undefined,
        customCostPerHour: t(['entityFields:contract.custom_cost_per_hour', 'entityFields:default.custom_cost_per_hour'], '') || undefined,
        salarySlip: t(['entityFields:contract.salary_slip', 'entityFields:default.salary_slip'], '') || undefined,
        user: t(['entityFields:contract.user', 'entityFields:default.user'], '') || undefined,
        tenant: t(['entityFields:contract.tenant', 'entityFields:default.tenant'], '') || undefined,
        project: t(['entityFields:contract.project', 'entityFields:default.project'], '') || undefined,
        createdAt: t(['entityFields:contract.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:contract.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:contract.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;