import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormInsertProps } from '@boilerplate/types/entity';
import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import Notistack from '@/lib/notistack';
import { catchGraphQlError } from '@/lib/catchError';

import ProjectEntity from '@/entities/project';
  import CustomerEntity from '@/entities/customer';
  import CategoryEntity from '@/entities/category';
  import TenantEntity from '@/entities/tenant';
  import LoggedHourEntity from '@/entities/loggedHour';
  import BudgetEntity from '@/entities/budget';
  import PlannedHourEntity from '@/entities/plannedHour';
  import HoursRequiredEntity from '@/entities/hoursRequired';
  import InvoiceEntity from '@/entities/invoice';
  import ProjectDateEntity from '@/entities/projectDate';
  import ContractEntity from '@/entities/contract';
  import ProjectDocumentEntity from '@/entities/projectDocument';
  import UserTenantRoleEntity from '@/entities/userTenantRole';
  import RegulationEntity from '@/entities/regulation';
  import UserEntity from '@/entities/user';

function ProjectBaseInsertForm({ formOnly = false, onClose, Entity = ProjectEntity, ...props }: BaseFormInsertProps<typeof ProjectEntity.model>) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createMutation, { loading }] = ProjectEntity.model.useCreate();

  const Component = formOnly ? EntityForm : EntityFormPage;

  const relationsOptionsQuery = ProjectEntity.model.useRelationsOptions({
    variables: {
          withCategories: true,
          withTenants: true,
          withRegulations: true,
          withResponsibleUsers: true,
          withControlUsers: true,
    }
  });

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(relationsOptionsQuery, [
              CategoryEntity,
              TenantEntity,
              RegulationEntity,
              UserEntity,
              UserEntity,
      ]),
    [relationsOptionsQuery]
  );

  const handleSubmit = (data) => {
    data.variables = getGraphqlData(Entity, data.variables, 'insert');

    createMutation(data)
      .then((result) => {
        Notistack.toast(t('crud:createdItem', { item: t('entities:project') }), { variant: 'success' });


        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.createProject);
      })
      .catch(catchGraphQlError);
  };

  if (relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="insert"
      Entity={Entity}
      loading={loading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptions}
    />
  );
}

export default ProjectBaseInsertForm;
