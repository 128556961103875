import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        description: {
          type: String,
          optional: true,
          form: true,
          view: true,
        },
        projects: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'project',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'projects.$': {
          type: String,
          optional: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:regulation.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:regulation.name', 'entityFields:default.name'], '') || undefined,
        description: t(['entityFields:regulation.description', 'entityFields:default.description'], '') || undefined,
        projects: t(['entityFields:regulation.project', 'entityFields:default.project'], '') || undefined,
});

export default schema;