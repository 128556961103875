import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import ProjectDateReminderBaseOrderFormSchema from '@/_base/projectDateReminder/ProjectDateReminderBaseOrderFormSchema';
import ProjectDateReminderBaseModel from '@/_base/projectDateReminder/ProjectDateReminderBaseModel';
import ProjectDateReminderBaseSimpleSchema from '@/_base/projectDateReminder/ProjectDateReminderBaseSimpleSchema';
import ProjectDateReminderBaseTableSchema from '@/_base/projectDateReminder/ProjectDateReminderBaseTableSchema';
import ProjectDateReminderBaseInsertForm from '@/_base/projectDateReminder/components/ProjectDateReminderBaseInsertForm';
import ProjectDateReminderBaseUpdateForm from '@/_base/projectDateReminder/components/ProjectDateReminderBaseUpdateForm';
import ProjectDateReminderBaseTable from '@/_base/projectDateReminder/components/ProjectDateReminderBaseTable';
import ProjectDateReminderBaseView from '@/_base/projectDateReminder/components/ProjectDateReminderBaseView';

const ProjectDateReminderEntity: Entity<typeof ProjectDateReminderBaseModel> = {
  name: 'project_date_reminder',

  simpleSchema: ProjectDateReminderBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(ProjectDateReminderBaseSimpleSchema),
    orderSchema: [...ProjectDateReminderBaseOrderFormSchema],
    component: ProjectDateReminderBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(ProjectDateReminderBaseSimpleSchema),
    orderSchema: [...ProjectDateReminderBaseOrderFormSchema],
    component: ProjectDateReminderBaseUpdateForm,
  },

  table: {
    schema: ProjectDateReminderBaseTableSchema,
    component: ProjectDateReminderBaseTable,
  },

  view: {
    component: ProjectDateReminderBaseView,
  },

  model: ProjectDateReminderBaseModel,

  routes: {},

  settings: {
    displayField: 'id',
  },
};

export default ProjectDateReminderEntity;
