import { ApolloError, FetchResult } from '@apollo/client';
import PageError from '@boilerplate/components/PageError';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormInsertProps, PreFilledFields } from '@boilerplate/types/entity';
import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import { CircularProgress } from '@mui/material';
import { sentenceCase } from 'change-case';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import UserEntity from '@/entities/user';
import { CreateUserMutation, User } from '@/graphql';
import Notistack from '@/lib/notistack';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

import useRelationsOptions from './useRelationsOptions';

export type UserInsertFormProps = {
  onClose?: (result: NonNullable<FetchResult<CreateUserMutation>['data']>['createUser'] | undefined) => Promise<void> | void;
  formOnly?: boolean;
};

function UserInsertForm({ formOnly = false, onClose, ...props }: BaseFormInsertProps<typeof UserEntity.model>) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentUser = useAuthenticatedUserStore();
  const [values, setValues] = useState({});

  const hiddenFields = useMemo(() => {
    if (currentUser.isSuperAdmin) {
      return [];
    }

    return ['note'];
  }, [currentUser.isSuperAdmin]);

  const [createMutation, { loading, error }] = UserEntity.model.useCreateOrganizationProjectUser();

  const Component = formOnly ? EntityForm : EntityFormPage;

  const preFilledFields = useMemo<PreFilledFields<User>>(
    () => ({
      ...props.preFilledFields,
      locale: { value: i18n.language, disabled: false },
      timeZone: { value: new Intl.DateTimeFormat().resolvedOptions().timeZone, disabled: false },
    }),
    [i18n.language, props.preFilledFields]
  );

  const relationsOptionsQuery = useRelationsOptions({ ...values, preFilledFields });

  const handleSubmit = (data) => {
    data.variables = getGraphqlData(UserEntity, data.variables, 'insert');

    createMutation(data)
      .then((result) => {
        Notistack.toast(t('crud:createdItem', { item: t('entities:user') }), { variant: 'success' });

        if (data.variables?.invite) {
          Notistack.toast(t('users:invite.success'), { variant: 'success' });
        }

        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.createUser);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  if (relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="insert"
      loading={loading}
      Entity={UserEntity}
      onChange={setValues}
      handleSubmit={handleSubmit}
      hiddenFields={hiddenFields}
      preFilledFields={preFilledFields}
      relationsOptions={relationsOptionsQuery.data}
    />
  );
}

export default UserInsertForm;
