import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        month: {
          type: Date,
          form: {
            component: 'DateTime',
          },
          view: true,
        },
        hours: {
          type: Number,
          form: true,
          view: true,
        },
        project: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'project',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        user: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'fullName',
          },
          form: true,
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:plannedHour.id', 'entityFields:default.id'], '') || undefined,
        month: t(['entityFields:plannedHour.month', 'entityFields:default.month'], '') || undefined,
        hours: t(['entityFields:plannedHour.hours', 'entityFields:default.hours'], '') || undefined,
        project: t(['entityFields:plannedHour.project', 'entityFields:default.project'], '') || undefined,
        user: t(['entityFields:plannedHour.user', 'entityFields:default.user'], '') || undefined,
        createdAt: t(['entityFields:plannedHour.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:plannedHour.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:plannedHour.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;