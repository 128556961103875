import config from '@frontend/config';
import { hasPermission, useUserStore } from '@frontend/stores/UserStore';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

type FooterItemProps = React.HTMLProps<HTMLParagraphElement> & {
  style?: React.CSSProperties;
};

function FooterItem({ style, children, ...props }: FooterItemProps) {
  return (
    <p style={{ marginTop: 6, marginBottom: 6, ...style }} {...props}>
      {children}
    </p>
  );
}

function Footer() {
  const theme = useTheme();
  const user = useUserStore();

  return (
    <footer
      style={{
        backgroundColor: theme.palette.mode === 'light' ? '#ebebeb' : '#272727',
        paddingTop: 64,
        paddingBottom: 64,
      }}
    >
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sm={12} md={9}>
            <Typography variant="h3">{config.app.name}</Typography>
            <FooterItem>&copy; {new Date().getFullYear()}</FooterItem>

            {hasPermission('admin-dashboard', user) && (
              <FooterItem>
                Build: <code>{config.app.gitCommit}</code>
              </FooterItem>
            )}
          </Grid>

          <Grid item sm={12} md={3}>
            <Typography variant="h3">Contact</Typography>
            <FooterItem>Finestri Subsidiemanagement</FooterItem>
            <FooterItem>Princenhagelaan 1, B1</FooterItem>
            <FooterItem>4813 DA Breda</FooterItem>
            <FooterItem>
              <a href="tel:0031850658009" style={{ color: 'inherit' }}>
                +31 85 065 80 09
              </a>
            </FooterItem>
            <FooterItem>
              <a href="mailto:info@finestri.nl" style={{ color: 'inherit' }}>
                info@finestri.nl
              </a>
            </FooterItem>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
