import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetLoggedHourDocument,
  GetLoggedHourQuery,
  GetLoggedHourQueryVariables,
  GetLoggedHoursDocument,
  GetLoggedHoursQuery,
  GetLoggedHoursQueryVariables,
  useGetLoggedHourQuery,
  useGetLoggedHourLazyQuery,
  useGetLoggedHoursQuery,
  useGetLoggedHoursLazyQuery,

    useGetAllRelatedDataForLoggedHourQuery,
    useGetAllRelatedDataForLoggedHourLazyQuery,
    GetAllRelatedDataForLoggedHourQuery,
    GetAllRelatedDataForLoggedHourQueryVariables,

  CreateLoggedHourDocument,
  CreateLoggedHourMutation,
  CreateLoggedHourMutationVariables,
  useCreateLoggedHourMutation,

  DeleteLoggedHourDocument,
  DeleteLoggedHourMutation,
  DeleteLoggedHourMutationVariables,
  useDeleteLoggedHourMutation,

  UpdateLoggedHourDocument,
  UpdateLoggedHourMutation,
  UpdateLoggedHourMutationVariables,
  useUpdateLoggedHourMutation,

    useCreatedLoggedHourSubscription,
    useUpdatedLoggedHourSubscription,
    useDeletedLoggedHourSubscription,
    useRestoredLoggedHourSubscription,
} from '@/graphql';

  type LoggedHourCollection = Dictionary<NonNullable<GetLoggedHoursQuery['loggedHours']['items']>[number]>;

const LoggedHourBaseModel = {
  get: (options: Omit<QueryOptions<GetLoggedHourQueryVariables, GetLoggedHourQuery>, 'query'>) => {
    return apolloClient.query<GetLoggedHourQuery, GetLoggedHourQueryVariables>({
      ...options,
      query: GetLoggedHourDocument,
    })
    .then(({ data }) => data.loggedHour);
  },

  useGet: useGetLoggedHourQuery,

  getAll: (options?: Omit<QueryOptions<GetLoggedHoursQueryVariables, GetLoggedHoursQuery>, 'query'>) => {
    return apolloClient
      .query<GetLoggedHoursQuery, GetLoggedHoursQueryVariables>({
        ...options,
        query: GetLoggedHoursDocument
      })
      .then(({ data }) => data.loggedHours.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetLoggedHoursQuery, GetLoggedHoursQueryVariables>) => {
    const hookResult = useGetLoggedHoursQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.loggedHours?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForLoggedHourQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForLoggedHourQuery, GetAllRelatedDataForLoggedHourQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForLoggedHourQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetLoggedHourLazyQuery,

  useGetAllLazy: useGetLoggedHoursLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForLoggedHourLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateLoggedHourMutation, CreateLoggedHourMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateLoggedHourMutation, CreateLoggedHourMutationVariables>({
      ...options,
      mutation: CreateLoggedHourDocument,
    });
  },

  useCreate: useCreateLoggedHourMutation,

  update: (options: Omit<MutationOptions<UpdateLoggedHourMutation, UpdateLoggedHourMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateLoggedHourMutation, UpdateLoggedHourMutationVariables>({
      ...options,
      mutation: UpdateLoggedHourDocument,
    });
  },

  useUpdate: useUpdateLoggedHourMutation,

  delete: (options: Omit<MutationOptions<DeleteLoggedHourMutation, DeleteLoggedHourMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteLoggedHourMutation, DeleteLoggedHourMutationVariables>({
      ...options,
      mutation: DeleteLoggedHourDocument,
    });
  },

  useDelete: useDeleteLoggedHourMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetLoggedHoursQuery, GetLoggedHoursQueryVariables>) => {
      const [collection, setCollection] = useState<LoggedHourCollection>({});

      const { items, loading, error, refetch } = LoggedHourBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedLoggedHourSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdLoggedHour?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdLoggedHour.id]: data.createdLoggedHour,
            }));
          }
        },
      });

      useUpdatedLoggedHourSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedLoggedHour?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedLoggedHour.id]: data.updatedLoggedHour,
            }));
          }
        },
      });

      useDeletedLoggedHourSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedLoggedHour?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedLoggedHour.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredLoggedHourSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredLoggedHour?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredLoggedHour.id]: data.restoredLoggedHour,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default LoggedHourBaseModel;
