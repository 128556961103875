import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';

// Error logging
import initializeSentry from './lib/sentry';

// Translations
import './lib/i18n';

// Data transfer
import './lib/axios';

import Root from '@/components/root';
import { HistoryRouter } from '@/routes/HistoryRouter';
import history from '@/routes/history';

import apolloClient from './lib/apolloClient';

initializeSentry();

const container = document.getElementById('root')!;
const component = (
  <HistoryRouter history={history}>
    {/* https://github.com/vazco/uniforms/issues/1194 */}
    {/* <React.StrictMode> */}
    <Root apolloClient={apolloClient} />
    {/* </React.StrictMode> */}
  </HistoryRouter>
);

if (container.childElementCount > 0) {
  hydrateRoot(container, component);
} else {
  createRoot(container).render(component);
}
