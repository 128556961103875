import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import AvailabilityBaseOrderFormSchema from '@/_base/availability/AvailabilityBaseOrderFormSchema';
import AvailabilityBaseModel from '@/_base/availability/AvailabilityBaseModel';
import AvailabilityBaseSimpleSchema from '@/_base/availability/AvailabilityBaseSimpleSchema';
import AvailabilityBaseTableSchema from '@/_base/availability/AvailabilityBaseTableSchema';
import AvailabilityBaseInsertForm from '@/_base/availability/components/AvailabilityBaseInsertForm';
import AvailabilityBaseUpdateForm from '@/_base/availability/components/AvailabilityBaseUpdateForm';
import AvailabilityBaseTable from '@/_base/availability/components/AvailabilityBaseTable';
import AvailabilityBaseView from '@/_base/availability/components/AvailabilityBaseView';

const AvailabilityEntity: Entity<typeof AvailabilityBaseModel> = {
  name: 'availability',

  simpleSchema: AvailabilityBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(AvailabilityBaseSimpleSchema),
    orderSchema: [...AvailabilityBaseOrderFormSchema],
    component: AvailabilityBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(AvailabilityBaseSimpleSchema),
    orderSchema: [...AvailabilityBaseOrderFormSchema],
    component: AvailabilityBaseUpdateForm,
  },

  table: {
    schema: AvailabilityBaseTableSchema,
    component: AvailabilityBaseTable,
  },

  view: {
    component: AvailabilityBaseView,
  },

  model: AvailabilityBaseModel,

  routes: {},

  settings: {
    displayField: 'id',
  },
};

export default AvailabilityEntity;
