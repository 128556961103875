const schema = [
        ['description'],
        ['startDatetime'],
        ['endDatetime'],
        ['break'],
        ['declarableTime'],
        ['addendum'],
        ['status'],
        ['category'],
        ['project'],
        ['user'],
        ['tenant'],
];

export default schema;
